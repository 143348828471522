var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GetValueFromData, isNullOrUndefined } from '@samc/react-ui-core';
import { getPicklistField } from '../types/PicklistFieldGetter';
export const getPicklistRowChangeHandler = ({ picklistField: _picklistFieldGetter, getPicklist, fieldName, partializer, evaluate, }) => (_a) => __awaiter(void 0, [_a], void 0, function* ({ node }) {
    const { data } = node;
    if (!data)
        return;
    const selectedValue = GetValueFromData(data, fieldName);
    if (isNullOrUndefined(selectedValue))
        return; // no selection to worry about!
    const picklistField = getPicklistField(_picklistFieldGetter, data, node);
    const gridPicklist = getPicklist(picklistField);
    if (!gridPicklist)
        return;
    const { valueSetting, validateDisplayExpression } = picklistField;
    if (!validateDisplayExpression)
        return; // not validated!
    let picklistItem;
    try {
        picklistItem = yield gridPicklist.resolveItem(selectedValue, valueSetting);
    }
    catch (e) {
        // if it can't be found... so be it!
        return;
    }
    const { displayExpression } = picklistItem;
    if (!displayExpression)
        return; // the option is for-sure safe
    // partialize and use the async evaluator (if available) to avoid lag!
    const partializedExpression = partializer.partialize(data, displayExpression);
    const expressionResult = yield evaluate(data, partializedExpression, true);
    const shouldDisplay = expressionResult !== false;
    if (shouldDisplay)
        return; // we're good!
    node.setDataValue(fieldName, null); // clear the field, this value is bad
});
export default getPicklistRowChangeHandler;
