/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
/* eslint-disable no-console */
import * as React from 'react';
import { PicklistSetting, getDisplayProperty, } from '@samc/picklist-api';
import { SearchableDropdown, ClassNameBuilder, } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { IconButton } from '@fluentui/react';
import { useEvaluator } from '@samc/expressions-react';
import { cloneDatum } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { GetJoinedReturn, HandleChange, ResultsToOptions, FlattenedToArray, getFilterExpressionForPicklist, withNullable, populateCustomNames, processFilterExpression, copyImperativeHandle, ManageDisplayExpressions, } from './PicklistFunctions';
import { PicklistToggle } from '../PicklistToggle/PicklistToggle';
import { PartialExpressionEvaluator } from '../../../utilities/PartialExpressionEvaluator/PartialExpressionEvaluator';
import { GridPicklist } from './GridPicklist';
// backwards compat
export { insensitiveEqual } from './PicklistFunctions';
const _TogglePicklist = (props, ref) => {
    const { fetchItems, selectedKeys, onChange, valueProperty, toggleClassName, displayProperty, disabled, className, isUpdated, hasError, enableToggleKeyboardListeners, isRequired, fillCellColor, filterObject, } = props;
    const toggleRef = React.useRef(null);
    const selectedKey = selectedKeys.at(0);
    const [css] = useStyletron();
    React.useImperativeHandle(ref, () => ({
        focusInput: () => undefined, // does nothing
        reload: () => withNullable(toggleRef.current, (tr) => tr.reload(), undefined),
        getSelectedItems: () => withNullable(toggleRef.current, (tr) => tr.getSelectedItems(), []),
        popoutElement: null,
    }), []);
    return (React.createElement("div", { className: ClassNameBuilder('picklist-toggle', 
        // default form field height is 25px, flex to always center field if height is overridden.
        css({ height: '25px', boxSizing: 'border-box', display: 'flex', alignItems: 'center' }), className, toggleClassName) },
        React.createElement(PicklistToggle, { ref: toggleRef, enableKeyboardNavigation: enableToggleKeyboardListeners, fetchItems: fetchItems, displayProperty: displayProperty, valueProperty: valueProperty, disabled: disabled, selectedKey: selectedKey, 
            // the min height is for grids and is the minimum reasonable looking height
            className: css({ height: '100% !important', width: '100%', minHeight: '16px' }), onChange: (v) => onChange === null || onChange === void 0 ? void 0 : onChange([v]), hasError: hasError, isUpdated: isUpdated, isRequired: isRequired, fillCellColor: fillCellColor, filterObject: filterObject })));
};
export const TogglePicklist = React.forwardRef(_TogglePicklist);
const _StandardPicklist = (props, ref) => {
    const { fetchItems, selectedKeys, onChange, valueProperty, displayProperty, disabled, showCheckboxes, showFilterButton, popoutClassName, styles, onOpen, onBlur, className, upperContentRenderer, overrideTooltip, isUpdated, hasError, closeOnChange, openOnRender, bodyOnly, bodyClassName, isRequired, fillCellColor, filterObject, } = props;
    const hardReloadPending = React.useRef(false);
    const dropdownRef = React.useRef(null);
    const [css] = useStyletron();
    const upperContentRendererInternal = React.useCallback(() => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css({ display: 'flex', justifyContent: 'end' }) },
            React.createElement(IconButton, { onClick: () => {
                    withNullable(dropdownRef.current, (v) => {
                        v.reload();
                        hardReloadPending.current = true;
                    }, undefined);
                }, iconProps: { iconName: 'Refresh' } })),
        upperContentRenderer && upperContentRenderer())), [css, upperContentRenderer]);
    const fetchDropdownItems = React.useCallback((params) => {
        const { filter, selectedKeys: innerSelectedKeys, selectionOnly, offset } = params;
        const filters = [];
        if (filter) {
            filters.push(getFilterExpressionForPicklist(filter, displayProperty));
        }
        const hardReload = hardReloadPending.current;
        hardReloadPending.current = false;
        return fetchItems(selectionOnly
            ? { ids: innerSelectedKeys, hardReload }
            : {
                offset,
                filters,
                hardReload,
            }).then(({ items, totalCount }) => ({
            options: ResultsToOptions(items, valueProperty, displayProperty),
            totalCount,
        }));
    }, [displayProperty, fetchItems, valueProperty]);
    const handleChange = React.useCallback((option) => {
        HandleChange(valueProperty, option, selectedKeys, onChange);
    }, [onChange, selectedKeys, valueProperty]);
    /**
     * Removes all selections, even those not in the dropdown
     */
    const onClear = React.useCallback(() => {
        if (onChange)
            onChange([]);
    }, [onChange]);
    const getMissingSelections = React.useCallback((ids) => fetchItems({ ids }).then((r) => ResultsToOptions(r.items, valueProperty, displayProperty)), [displayProperty, fetchItems, valueProperty]);
    React.useImperativeHandle(ref, () => ({
        get popoutElement() {
            return withNullable(dropdownRef.current, (dr) => dr.popoutElement, null);
        },
        reload: () => withNullable(dropdownRef.current, (dr) => dr.reload(), undefined),
        focusInput: (v) => withNullable(dropdownRef.current, (dr) => dr.focusInput(v), undefined),
        getSelectedItems: () => withNullable(dropdownRef.current, (dr) => dr
            .getSelectedOptions()
            .map((o) => o.data)
            .filter((o) => !!o), []),
    }), []);
    return (React.createElement(SearchableDropdown, { ref: dropdownRef, options: fetchDropdownItems, onChange: handleChange, disabled: disabled, selectedKeys: selectedKeys, showCheckboxes: showCheckboxes, showFilterButton: showFilterButton, popoutClassName: popoutClassName, className: ClassNameBuilder('picklist', className), getMissingSelections: getMissingSelections, styles: styles, onClose: onBlur, onOpen: onOpen, closeOnChange: closeOnChange, onClearSelection: onClear, upperContentRenderer: upperContentRendererInternal, overrideTooltip: overrideTooltip, isUpdated: isUpdated, hasError: hasError, openOnRender: openOnRender, bodyClassName: bodyClassName, bodyOnly: bodyOnly, isRequired: isRequired, fillCellColor: fillCellColor, expressionData: filterObject }));
};
const StandardPicklist = React.forwardRef(_StandardPicklist);
const _Picklist = (props, ref) => {
    const { fetchItems: fetchItemsProp, selectedKey: _selectedKeys, filterObject, picklist: picklistField, bodyOnly, } = props;
    const picklistRef = React.useRef(null);
    const { evaluate } = useEvaluator();
    const partialEvaluator = React.useMemo(() => new PartialExpressionEvaluator(evaluate), [evaluate]);
    const selectedIds = React.useMemo(() => _selectedKeys || [], [_selectedKeys]);
    const { filterExpression } = picklistField !== null && picklistField !== void 0 ? picklistField : {};
    const preprocessedExpression = React.useMemo(() => (filterExpression ? processFilterExpression(filterExpression, filterObject) : undefined), [filterExpression, filterObject]);
    /**
     * Processes display expressions/isActive
     */
    const processDisplayExpressions = React.useCallback((items) => ManageDisplayExpressions(items, partialEvaluator, filterObject), [filterObject, partialEvaluator]);
    const fetchItems = React.useCallback((params) => {
        let newParams = params;
        if (preprocessedExpression) {
            newParams = cloneDatum(params);
            if (!newParams.filters)
                newParams.filters = [];
            newParams.filters.push(preprocessedExpression);
        }
        return fetchItemsProp(newParams).then(({ items, totalCount }) => ({
            items: processDisplayExpressions(populateCustomNames(items)),
            totalCount,
        }));
    }, [fetchItemsProp, processDisplayExpressions, preprocessedExpression]);
    // forwards ref to parent
    React.useImperativeHandle(ref, () => copyImperativeHandle(picklistRef), []);
    // reloads picklist if the preprocessed expression changes
    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => { var _a; return (_a = picklistRef.current) === null || _a === void 0 ? void 0 : _a.reload(); };
    }, [preprocessedExpression]);
    if (bodyOnly ||
        !picklistField ||
        picklistField.setting === PicklistSetting.Single ||
        picklistField.setting === PicklistSetting.MultiSelect) {
        const { renderAsToggle } = picklistField !== null && picklistField !== void 0 ? picklistField : {};
        if (renderAsToggle && !bodyOnly)
            return React.createElement(TogglePicklist, Object.assign({ ref: picklistRef }, props, { selectedKeys: selectedIds, fetchItems: fetchItems }));
        return React.createElement(StandardPicklist, Object.assign({ ref: picklistRef }, props, { selectedKeys: selectedIds, fetchItems: fetchItems }));
    }
    return (React.createElement(GridPicklist, Object.assign({ ref: picklistRef }, props, { picklist: picklistField, selectedKeys: selectedIds, fetchItems: fetchItems })));
};
/**
 * A Picklist, which wraps its three different subtypes: GridPicklist, TogglePicklist, and StandardPicklist.
 */
export const Picklist = React.forwardRef(_Picklist);
const _PicklistWrapper = (props, ref) => {
    /**
     * Turns the onChange selected ids into a concatenated string consumable by the task service.
     * @param value the value returned by the picklist onChange
     */
    const { picklist, fetchItems, onChange, value, data, disabled, className, isUpdated, hasError, supressTooltip, toggleClassName, openOnRender, onBlur, onOpen, enableToggleKeyboardListeners, useGridFieldConfigurations, fillCellColor, isRequired, } = props;
    const multiSelect = picklist.setting === PicklistSetting.MultiSelect || picklist.setting === PicklistSetting.MultiGridSelectInput;
    const handleChange = onChange
        ? (v) => {
            let selection;
            if (v.length) {
                if (multiSelect)
                    selection = v;
                else
                    selection = v.slice(-1);
            }
            else
                selection = [];
            onChange(GetJoinedReturn(selection));
        }
        : undefined;
    const selectedKey = React.useMemo(() => FlattenedToArray(value || ''), [value]);
    return (React.createElement(Picklist, { ref: ref, picklist: picklist, selectedKey: selectedKey, valueProperty: "id", displayProperty: getDisplayProperty(picklist.displaySetting), filterObject: data, disabled: disabled, toggleClassName: toggleClassName, onChange: handleChange, showCheckboxes: multiSelect, showFilterButton: true, className: className, fetchItems: fetchItems, isUpdated: isUpdated, hasError: hasError, overrideTooltip: supressTooltip, openOnRender: openOnRender, onBlur: onBlur, onOpen: onOpen, enableToggleKeyboardListeners: enableToggleKeyboardListeners, useGridFieldConfigurations: useGridFieldConfigurations, fillCellColor: fillCellColor, isRequired: isRequired }));
};
/**
 * A helper method which takes in a picklist and returns a control whose attributes
 * are the remaining fields required to set up a serverside picklist control.
 */
export const PicklistWrapper = React.forwardRef(_PicklistWrapper);
export default Picklist;
