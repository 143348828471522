var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { InputType, usePicklistGetter, usePicklistItemGetter, } from '@samc/picklist-api';
import { toastError, useRefWrapper } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
import { getPicklistCellEditor } from '../../grid/editors/PicklistCellEditor/PicklistCellEditor';
import { PicklistColumnFloatingFilter } from '../../grid/filters/PicklistColumnFilter/PicklistColumnFloatingFilter';
import { PicklistCustomTooltip, } from '../../grid/renderers/PicklistCellRenderer/PicklistCustomTooltip';
import { PicklistColumnFilter } from '../../grid/filters/PicklistColumnFilter/PicklistColumnFilter';
import { PicklistFieldValidator } from '../../grid/field-validators/PicklistFieldValidator/PicklistFieldValidator';
import { PicklistCellRenderer, } from '../../grid/renderers/PicklistCellRenderer/PicklistCellRenderer';
import { GridPicklistManager } from '../../utilities/GridPicklistManager/GridPicklistManager';
import { PartialExpressionEvaluator } from '../../utilities/PartialExpressionEvaluator/PartialExpressionEvaluator';
import { getPicklistFieldSort } from '../../atoms/controls/Picklist/PicklistFunctions';
import { getPicklistValueGetter } from '../../grid/value-getters/PicklistValueGetter/PicklistValueGetter';
import { getPicklistRowChangeHandler } from '../../grid/change-handlers/PicklistRowChangeHandler';
export const transformPicklistGridFieldConfigurations = (gridFields, getGridPicklist, evaluate, initialFrameworkComponents, useGridFieldConfigurations, customRenderers, evaluateAsync) => {
    const frameworkComponents = Object.assign(Object.assign({}, initialFrameworkComponents), { AgPicklistFloatingFilter: PicklistColumnFloatingFilter, AgPicklistTooltip: PicklistCustomTooltip, PicklistCellRenderer, AgPicklistFilter: PicklistColumnFilter });
    const partialEvaluator = new PartialExpressionEvaluator(evaluate);
    const gridFieldConfigs = gridFields.map((f) => {
        const { picklistField } = f;
        if (!picklistField)
            return f;
        try {
            const field = Object.assign({}, f);
            field.editor = getPicklistCellEditor(picklistField, getGridPicklist, useGridFieldConfigurations);
            field.type = 'string'; // left aligns
            if (typeof picklistField !== 'function' && field.filter !== false) {
                field.floatingFilterComponent = 'AgPicklistFloatingFilter';
                field.floatingFilterComponentParams = {
                    picklistField,
                    getPicklist: getGridPicklist,
                    useGridFieldConfigurations,
                };
                field.filter = 'AgPicklistFilter';
                field.filterParams = {
                    picklistField,
                    getPicklist: getGridPicklist,
                };
            }
            else {
                field.filter = false;
            }
            field.valueGetter = getPicklistValueGetter(picklistField, getGridPicklist);
            field.fieldValidator = PicklistFieldValidator(picklistField, getGridPicklist, partialEvaluator, f.field);
            field.suppressTypeThrough = true; // don't allow typing into field
            field.tooltipComponent = 'AgPicklistTooltip';
            field.tooltipComponentParams = {
                picklistField,
                getPicklist: getGridPicklist,
            };
            field.tooltipValueGetter = ({ value }) => value; // wipe away any existing ones
            field.cellRendererParams = {
                customRenderer: customRenderers === null || customRenderers === void 0 ? void 0 : customRenderers[field.field],
                getPicklist: getGridPicklist,
                picklistField,
            };
            field.cellRenderer = 'PicklistCellRenderer';
            field.onRowChanged = getPicklistRowChangeHandler({
                picklistField,
                getPicklist: getGridPicklist,
                fieldName: field.field,
                partializer: partialEvaluator,
                evaluate: evaluateAsync !== null && evaluateAsync !== void 0 ? evaluateAsync : evaluate,
            });
            const originalSuppressKeyboardEvent = field.suppressKeyboardEvent;
            // allow using the enter key in the editor
            field.suppressKeyboardEvent = (params) => {
                if (originalSuppressKeyboardEvent && originalSuppressKeyboardEvent(params))
                    return true;
                const { editing, event } = params;
                const { key } = event;
                if (editing && key === 'Enter')
                    return true;
                return false;
            };
            return field;
        }
        catch (e) {
            toastError(String(e));
            return f;
        }
    });
    return {
        wrappedFields: gridFieldConfigs,
        frameworkComponents,
    };
};
/**
 * Builds out the gridFieldConfigurations for a domain that contains
 * picklist fields to be consumed by AgGrid.
 *
 * @param fieldConfigurationMembers A dictionary relating field names to field configuration members
 * @param gridFields The fields to be displayed on the grid
 * @param filters Filter expressions to filter the results
 * @param useGridFieldConfigurations A hook used to populate listView grid field configurations in multicolumn contexts, if not supplied, listViewId will not work.
 * @returns Returns updated GridFieldConfigurations including picklist
 *          renderers, filters, tooltips, etc., as well as the necessary
 *          frameworkComponents.
 */
export const usePicklistGridFields = (fieldConfigurationMembers, gridFields, filters, initialFrameworkComponents, gridSortingData, gridAdhocFilter, useGridFieldConfigurations, customRenderers) => {
    const getPicklistItems = usePicklistItemGetter();
    const getDBPicklist = usePicklistGetter();
    const asyncEvaluator = useEvaluator(undefined, true);
    const { evaluate } = useEvaluator();
    // indexed by picklist field id
    const picklists = React.useMemo(() => {
        return Object.values(fieldConfigurationMembers).reduce((all, cur) => {
            const { picklistField } = cur;
            if (!picklistField)
                return all;
            const gridPicklist = new GridPicklistManager({
                picklistGetter: () => {
                    return getDBPicklist(true, picklistField.picklistId);
                },
                itemGetter: (picklist, params) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    const newParams = Object.assign({}, params);
                    const defaultSort = getPicklistFieldSort(picklistField);
                    if (!newParams.ids) {
                        newParams.sortOverride = (_a = newParams.sortOverride) !== null && _a !== void 0 ? _a : defaultSort;
                        const newFilters = [];
                        if (filters)
                            newFilters.push(...filters);
                        if (newParams.filters)
                            newFilters.push(...newParams.filters); // param takes precedence
                        else if (gridAdhocFilter)
                            newFilters.push(gridAdhocFilter);
                        newParams.filters = newFilters;
                    }
                    return getPicklistItems(picklist, newParams);
                }),
            });
            return Object.assign(Object.assign({}, all), { [picklistField.id]: gridPicklist });
        }, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldConfigurationMembers, filters, gridAdhocFilter, gridSortingData]);
    const picklistsRef = useRefWrapper(picklists);
    // this function will never re-calculate, safe to pass to grid as callbacks
    const getPicklist = React.useCallback((picklistField) => {
        const curVal = picklistsRef.current[picklistField.id];
        return curVal;
    }, [picklistsRef]);
    const gridFieldConfigsWithPicklistFields = React.useMemo(() => {
        const newGridFields = [];
        gridFields.forEach((gf) => {
            let picklistField;
            const fieldConfiguration = fieldConfigurationMembers[gf.field];
            if (fieldConfiguration) {
                const { inputType, picklistField: fetchedPicklistField } = fieldConfiguration;
                if (inputType === InputType.Picklist && fetchedPicklistField)
                    picklistField = fetchedPicklistField;
            }
            newGridFields.push(Object.assign(Object.assign({}, gf), { picklistField }));
        });
        return newGridFields;
    }, [gridFields, fieldConfigurationMembers]);
    const { wrappedFields, frameworkComponents } = React.useMemo(() => transformPicklistGridFieldConfigurations(gridFieldConfigsWithPicklistFields, getPicklist, evaluate, initialFrameworkComponents, useGridFieldConfigurations, customRenderers, asyncEvaluator.evaluate), [
        evaluate,
        getPicklist,
        gridFieldConfigsWithPicklistFields,
        initialFrameworkComponents,
        useGridFieldConfigurations,
        customRenderers,
        asyncEvaluator,
    ]);
    return { wrappedFields, frameworkComponents, picklists };
};
export default usePicklistGridFields;
