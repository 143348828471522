var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { useRequest } from '@samc/react-ui-request';
import { useQueryClient } from 'react-query';
import { getDomain } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { DOMAIN_KEY, getDomainQueryKey, INDEXABLE_DOMAINS_KEY } from '../useDomainGetter/useDomainGetter';
export { DOMAIN_KEY, getDomainQueryKey };
export function useDomain(domainId) {
    const api = React.useContext(ApiContext);
    const queryClient = useQueryClient();
    const query = (_id) => __awaiter(this, void 0, void 0, function* () {
        if (!_id)
            return undefined;
        const allDomainItems = queryClient.getQueryData(INDEXABLE_DOMAINS_KEY, {
            stale: false,
        });
        if (allDomainItems && allDomainItems[_id])
            return allDomainItems[_id];
        return getDomain(api.AggregatorApi, _id, api.requestInit);
    });
    return useRequest(DOMAIN_KEY, query, ApiContext, domainId);
}
