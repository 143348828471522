import * as React from 'react';
import useMutation from '../useMutation/useMutation';
import { savePicklist } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { INDEXABLE_PICKLISTS_KEY } from '../useIndexablePicklists/useIndexablePicklists';
/**
 * Returns an array containing a method to save a PicklistItems[] for a given picklist and an object
 * representing the current state of the save request. Calling the save function
 * will invalidate the cache for any requests for a picklist with the same ID.
 * @param fieldConfiguration FieldConfiguration to be saved.
 */
// eslint-disable-next-line import/prefer-default-export
export const usePicklistSaver = () => {
    const api = React.useContext(ApiContext);
    const query = savePicklist(api.AggregatorApi, api.requestInit);
    return useMutation(query, [INDEXABLE_PICKLISTS_KEY, api.AggregatorApi]);
};
