/* eslint-disable react/display-name */
import * as React from 'react';
import { PicklistSetting, getDisplayProperty, PicklistDisplaySetting, } from '@samc/picklist-api';
import { Picklist } from '../../../atoms/controls/Picklist/Picklist';
import './PicklistColumnFloatingFilter.css';
const EMPTY_VALUES = [];
export const PicklistColumnFloatingFilter = (props) => {
    var _a;
    const { picklistField, api, getPicklist, useGridFieldConfigurations, model, onModelChange } = props;
    const { typedValues: selectedIds } = model !== null && model !== void 0 ? model : { typedValues: EMPTY_VALUES };
    const fetchItems = (p) => {
        const gridPicklist = getPicklist(picklistField);
        if (!gridPicklist) {
            // eslint-disable-next-line no-console
            console.warn('Picklist configuration not found');
            return Promise.resolve({ items: [], totalCount: 0 });
        }
        return gridPicklist.fetchItems(p);
    };
    const floatingPicklistField = React.useMemo(() => {
        return Object.assign(Object.assign({}, picklistField), { renderAsToggle: false, setting: picklistField.setting === PicklistSetting.GridSelectInput ||
                picklistField.setting === PicklistSetting.MultiGridSelectInput
                ? PicklistSetting.MultiGridSelectInput
                : PicklistSetting.MultiSelect });
    }, [picklistField]);
    const valueChanged = React.useCallback((values) => {
        if (values.length === 0)
            onModelChange(null);
        else {
            onModelChange({
                typedValues: values,
                values: values.map(String),
                filterType: 'set',
            });
        }
    }, [api]);
    return (React.createElement("div", { role: "presentation", className: "ag-labeled ag-label-align-left ag-text-field ag-input-field", style: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flex: 1,
        } },
        React.createElement("div", { className: "ag-input-field-label ag-label ag-hidden ag-text-field-label" }),
        React.createElement("div", { className: "ag-wrapper ag-input-wrapper ag-text-field-input-wrapper picklist-floating-filter", role: "presentation" },
            React.createElement(Picklist, { fetchItems: fetchItems, picklist: floatingPicklistField, selectedKey: selectedIds, showCheckboxes: true, onChange: valueChanged, valueProperty: "id", displayProperty: getDisplayProperty((_a = floatingPicklistField === null || floatingPicklistField === void 0 ? void 0 : floatingPicklistField.displaySetting) !== null && _a !== void 0 ? _a : PicklistDisplaySetting.CustomShortName), useGridFieldConfigurations: useGridFieldConfigurations }))));
};
export default PicklistColumnFloatingFilter;
