var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { usePicklistItemGetter, usePicklistSaver } from '@samc/picklist-api';
import { usePicklistItemsSaver } from '@samc/picklist-api/lib/usePicklistItemsSaver/usePicklistItemsSaver';
import { toastError, toastInfo, toastSuccess } from '@samc/react-ui-core';
import PicklistCrudEditor from './PicklistCrudEditor';
const ServerSidePicklistCrudEditor = (props) => {
    const { picklist, onClose, onUpdate } = props;
    const [savePicklist] = usePicklistSaver();
    const [handleResultsChange] = usePicklistItemsSaver(picklist);
    const picklistItemGetter = usePicklistItemGetter();
    const fetchItems = React.useCallback((params) => picklistItemGetter(picklist, params), [picklist, picklistItemGetter]);
    const handlePicklistSave = React.useCallback((item) => __awaiter(void 0, void 0, void 0, function* () {
        toastInfo('Saving Picklist...');
        yield savePicklist(item)
            .then((p) => {
            onUpdate(p);
            toastSuccess('Picklist Saved');
        })
            .catch(() => toastError('Picklist Save Failed'));
    }), [onUpdate, savePicklist]);
    const savePicklistItems = React.useCallback((items) => __awaiter(void 0, void 0, void 0, function* () {
        toastInfo('Saving Picklist Items...');
        return new Promise((res, rej) => {
            handleResultsChange(items)
                .then((result) => {
                if (result.errorCount) {
                    toastError(`Picklist Items Failed to Save: ${result.error}`);
                    rej();
                }
                else {
                    toastSuccess('Picklist Items Saved');
                    res();
                }
            })
                .catch((e) => {
                toastError(`Picklist Items Failed to Save: ${String(e)}`);
                rej();
            });
        });
    }), [handleResultsChange]);
    return (React.createElement(PicklistCrudEditor, { picklist: picklist, savePicklist: handlePicklistSave, fetchItems: fetchItems, onCancel: onClose, saveModifiedResults: savePicklistItems }));
};
export default ServerSidePicklistCrudEditor;
