var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { PicklistType } from '@samc/picklist-api/lib/model/Picklist';
import { useDirtinessPrompt } from '@samc/react-ui-core';
import ServerSidePicklistConfigurationBody from '../../bodies/PicklistConfigurationBody/ServerSidePicklistConfigurationBody';
import { PicklistHeader } from '../../../atoms/PicklistHeader/PicklistHeader';
const getId = () => `_${Math.random().toString(36).substr(2, 9)}`;
const PicklistCrudEditor = (props) => {
    const { picklist, fetchItems, savePicklist, saveModifiedResults, onCancel } = props;
    const dirtinessScope = React.useRef(null);
    const { promptAndContinue, isDirty } = useDirtinessPrompt({
        scope: dirtinessScope,
    });
    const initialPicklist = React.useMemo(() => (Object.assign(Object.assign({}, picklist), { id: !picklist.id ? getId() : picklist.id })), [picklist]);
    const isNew = React.useMemo(() => picklist.id === '', [picklist.id]);
    const [editedPicklist, setEditedPicklist] = React.useState(initialPicklist);
    const picklistHasBeenEdited = JSON.stringify(editedPicklist) !== JSON.stringify(initialPicklist);
    const [changedItems, setChangedItems] = React.useState([]); // only modified PicklistItems
    const itemsHaveBeenChanged = changedItems.length > 0;
    React.useEffect(() => {
        setEditedPicklist(initialPicklist);
    }, [initialPicklist]);
    React.useEffect(() => {
        setChangedItems([]);
    }, [fetchItems]);
    const handleSave = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const isCustom = !picklist.domainId;
            // save the items first before updating the picklist
            if (!isCustom && itemsHaveBeenChanged && saveModifiedResults) {
                yield saveModifiedResults(changedItems);
                setChangedItems([]);
            }
            if (picklistHasBeenEdited)
                yield savePicklist(editedPicklist);
        }
        catch (e) {
            // pass
        }
    }), [
        picklist.domainId,
        itemsHaveBeenChanged,
        saveModifiedResults,
        picklistHasBeenEdited,
        savePicklist,
        editedPicklist,
        changedItems,
    ]);
    const handleCancel = React.useMemo(() => (onCancel ? () => promptAndContinue(onCancel) : undefined), [onCancel, promptAndContinue]);
    const handleChangeData = React.useCallback((args) => {
        const editedItems = Object.values(args.changedData);
        // mark customized if added row on system picklist
        if (editedPicklist.type === PicklistType.Standard &&
            !editedPicklist.isCustomized &&
            editedItems.some((i) => i.__ADDED__))
            setEditedPicklist((p) => (Object.assign(Object.assign({}, p), { isCustomized: true })));
        if (picklist.domainId) {
            setChangedItems(editedItems);
        }
        else {
            setEditedPicklist((ep) => {
                let items = [...(ep.picklistItems || [])];
                editedItems.forEach((ei) => {
                    if (ei.__DELETED__) {
                        items = items.filter((i) => i.id !== ei.id);
                    }
                    else {
                        const index = items.findIndex((i) => i.id === ei.id);
                        if (index > -1) {
                            items[index] = ei;
                        }
                        else if (ei.__ADDED__) {
                            items.push(ei);
                        }
                        else {
                            // ei is new but not __ADDED__ - Ignore 🤷‍♀️
                        }
                    }
                });
                // Remove __ADDED__ rows from items if they're absent from editedItems (indicates id change)
                items = items.filter((item) => item.__ADDED__ ? editedItems.some((ei) => ei.id === item.id && ei.__ADDED__) : true);
                return Object.assign(Object.assign({}, ep), { picklistItems: items });
            });
        }
    }, [editedPicklist.isCustomized, editedPicklist.type, picklist.domainId]);
    const isValid = React.useMemo(() => !!editedPicklist.name && editedPicklist.status !== undefined && editedPicklist.status !== undefined, [editedPicklist.name, editedPicklist.status]);
    return (React.createElement("div", { className: "picklist-config" },
        React.createElement(PicklistHeader, { isNew: isNew, submit: handleSave, cancel: handleCancel, submitDisabled: !isDirty || !isValid, cancelDisabled: false }),
        React.createElement(ServerSidePicklistConfigurationBody, { initialPicklist: initialPicklist, onChangePicklist: setEditedPicklist, onSubmit: handleSave, fetchItems: fetchItems, onChangeItem: handleChangeData, isNew: isNew })));
};
export default PicklistCrudEditor;
