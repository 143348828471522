/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Spinner } from '@fluentui/react';
import { useDomain } from '@samc/picklist-api';
import { ExpressionEntryField } from '@samc/react-ui-form';
export const PicklistFieldExpressionCurrentRecordPrefix = 'CurrentRecord__';
const _FilterExpressionField = (props, ref) => {
    const { data: picklistField, value, onChange, } = props;
    const { extraProps: originalExtraProps } = props;
    const { domainId } = picklistField !== null && picklistField !== void 0 ? picklistField : {};
    const { targetDomainId } = (originalExtraProps !== null && originalExtraProps !== void 0 ? originalExtraProps : {});
    const [internalValue, setInternalValue] = React.useState(value);
    const internalOnChange = React.useCallback((...params) => {
        onChange(...params);
        setInternalValue(params[0]);
    }, [onChange]);
    const { data: targetDomain, isLoading: isLoadingTargetDomain } = useDomain(targetDomainId);
    const { data: domain, isLoading: isDomainLoading } = useDomain(domainId);
    const targetFields = React.useMemo(() => {
        if (!targetDomain)
            return [];
        return targetDomain.fields.map((f) => `${PicklistFieldExpressionCurrentRecordPrefix}${f.fieldName}`); // fields on the current record
    }, [targetDomain]);
    const domainFields = React.useMemo(() => {
        if (!domain)
            return [];
        return domain.fields.map((f) => f.fieldName);
    }, [domain]);
    const extraProps = React.useMemo(() => (Object.assign(Object.assign({}, originalExtraProps), { fields: domainFields.concat(targetFields) })), [domainFields, originalExtraProps, targetFields]);
    // keep state synced
    React.useEffect(() => {
        setInternalValue(value);
    }, [value]);
    if (isLoadingTargetDomain || isDomainLoading)
        return React.createElement(Spinner, null);
    return (React.createElement(ExpressionEntryField, Object.assign({ ref: ref }, props, { onChange: internalOnChange, value: internalValue, extraProps: extraProps })));
};
export const FilterExpressionField = React.forwardRef(_FilterExpressionField);
export default FilterExpressionField;
