export const SortOrderFieldConfig = () => {
    return {
        headerName: 'Sort Order',
        field: 'sortOrder',
        editableRule: '[isActive] == true',
        requiredFieldRule: '[isActive] == true',
        width: 125,
    };
};
export default SortOrderFieldConfig;
