import { GetValueFromData, isNullOrUndefined } from '@samc/react-ui-core';
import { getPicklistField } from '../../types/PicklistFieldGetter';
import { getGridDisplayValue } from '../../renderers/PicklistCellRenderer/Utils';
import { FlattenedToArray } from '../../../atoms/controls/Picklist/PicklistFunctions';
export const getPicklistValueGetter = (picklistFieldGetter, getGridPicklist) => (params) => {
    var _a;
    const { colDef, data, node } = params;
    const { field } = colDef;
    if (!field)
        return null;
    const value = GetValueFromData(data, field);
    const picklistField = getPicklistField(picklistFieldGetter, data, node);
    const gridPicklist = getGridPicklist(picklistField);
    if (!gridPicklist)
        return value;
    if (isNullOrUndefined(value))
        return null;
    const arrayVal = (_a = FlattenedToArray(value)) !== null && _a !== void 0 ? _a : [];
    const displayValue = getGridDisplayValue(picklistField, arrayVal, (id) => gridPicklist.getCachedItem(id));
    return displayValue !== null && displayValue !== void 0 ? displayValue : value;
};
export default getPicklistValueGetter;
