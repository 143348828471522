import { useRequestBase, getQueryKey } from '@samc/react-ui-request';
import { useQueryClient } from 'react-query';
import { getPicklists } from '../api/Requests';
import { ApiContext, useApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { getPicklistQueryKey, INDEXABLE_PICKLISTS_KEY } from '../usePicklistGetter/usePicklistGetter';
// backwards compat
export { INDEXABLE_PICKLISTS_KEY } from '../usePicklistGetter/usePicklistGetter';
/**
 * Gets the query key used to fetch all picklists
 */
export const getIndexablePicklistsQueryKey = () => getQueryKey(INDEXABLE_PICKLISTS_KEY, []);
/**
 * Retrieves a record of all picklists by id
 */
export const useIndexablePicklists = () => {
    const api = useApiContext();
    const queryClient = useQueryClient();
    const query = () => getPicklists(api.AggregatorApi, api.requestInit).then((flatResponse) => {
        return flatResponse.reduce((all, cur) => {
            // force the latest and greatest into the query context, will push to any useRequest hooks.
            queryClient.setQueryData(getPicklistQueryKey(cur.id), cur);
            return Object.assign(Object.assign({}, all), { [cur.id]: cur });
        }, {});
    });
    return useRequestBase({
        key: INDEXABLE_PICKLISTS_KEY,
        query,
        requestContext: ApiContext,
        options: {
            staleTime: CacheTime.picklist,
        },
    });
};
export default useIndexablePicklists;
