import { getDisplayProperty } from '@samc/picklist-api';
import { getDisplayValue } from '../../../atoms/controls/Picklist/PicklistFunctions';
/**
 * Returns if the cell is editable
 */
export const isEditable = (props) => {
    const { column, node } = props;
    if (!column)
        return false;
    return column.isCellEditable(node);
};
export const getGridDisplayValue = (picklistField, selectedIds, getPicklistItem) => {
    if (!selectedIds)
        return undefined;
    const displayProperty = getDisplayProperty(picklistField.displaySetting);
    const optionalDisplayProperty = picklistField.optionalDisplaySetting
        ? getDisplayProperty(picklistField.optionalDisplaySetting)
        : undefined;
    const separator = picklistField.optionalDisplaySetting ? ';' : ',';
    return selectedIds
        .reduce((prev, currentId) => {
        const item = getPicklistItem(currentId);
        if (item) {
            if (optionalDisplayProperty)
                return prev.concat(`${getDisplayValue(item, displayProperty, 'id')} (${getDisplayValue(item, optionalDisplayProperty, 'id')})`);
            return prev.concat(String(getDisplayValue(item, displayProperty, 'id')));
        }
        return prev.concat([currentId]);
    }, [])
        .join(`${separator} `);
};
