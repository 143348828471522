export const getNextId = (api) => {
    if (!api)
        return '';
    let id = 0;
    api.forEachNode((node) => {
        // Use node id rather than picklist id to avoid assigning the same internal node id to multiple rows
        const nodeId = node.id;
        const itemId = Number(nodeId);
        if (Number.isFinite(itemId))
            id = Math.min(id, itemId);
    });
    return String(id - 1);
};
export const getNextSortOrder = (api) => {
    if (!api)
        return 0;
    let sortOrder = 0;
    api.forEachNode((node) => {
        const picklistItem = node.data;
        sortOrder = Math.max(sortOrder, picklistItem.sortOrder || 0);
    });
    return sortOrder + 1;
};
// exported for testing only
export const MapOrderedRow = (value, index) => {
    const row = value;
    row.sortOrder = index + 1;
    return row;
};
export const CreateItem = (api) => {
    const newRow = {
        id: getNextId(api),
        shortName: '',
        longName: '',
        displayExpression: '',
        customShortName: '',
        customLongName: '',
        isActive: true,
        isSystemDefined: false,
        __ADDED__: true,
        sortOrder: getNextSortOrder(api),
    };
    return newRow;
};
// exported for testing only
export const DefaultSortOrderToZero = (value) => value.sortOrder || 0;
export const RemoveSortOrderFromInactive = (editedRow) => {
    const row = Object.assign({}, editedRow);
    if (!row.isActive)
        row.sortOrder = undefined;
    return row;
};
