import React from 'react';
import { LayerWrapper, toastError, ToastMessageContainer, PromptContextProvider } from '@samc/react-ui-core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router as CustomRouter } from 'react-router-dom';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { ApiProvider } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BrowserHistory, HashHistory } from 'history';
import { AuthorizationWrapper, useUserAuthenticationState } from '@samc/single-spa-authentication';
import { useTenantState } from '@samc/single-spa-tenant-selector';
import { HistoryProvider } from '@samc/react-ui-history';
import { useClientConfiguration } from '@samc/single-spa-client-configuration';
import { Router } from './templates/Router/Router';
import 'react-toastify/dist/ReactToastify.css';
import './style/picklist.css';

const _queryClient = new QueryClient();
export interface SingleSpaProps {
    // eslint-disable-next-line react/no-unused-prop-types
    styletronEngine: Styletron;
    browserHistory: BrowserHistory;
    hashHistory: HashHistory;
    queryClient?: QueryClient;
}

export const Root = (props: SingleSpaProps): React.ReactElement | null => {
    const { browserHistory, hashHistory, queryClient } = props;

    const [gracePeriodActive, setGracePeriodActive] = React.useState(true);
    React.useEffect(() => {
        const timer = setTimeout(() => setGracePeriodActive(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    const tenantState = useTenantState();
    const tenantName = tenantState?.name;

    const authState = useUserAuthenticationState();
    const isUserAuthenticated = React.useMemo(() => !!authState?.isAuthenticated, [authState]);
    const token = authState?.accessToken?.value;

    const [location, setLocation] = React.useState(hashHistory.location);

    React.useEffect(() => {
        return hashHistory.listen((l) => setLocation(l.location));
    }, [hashHistory]);

    const { result: clientConfiguration } = useClientConfiguration();

    if (clientConfiguration === undefined)
        return gracePeriodActive ? null : (
            <MessageBar messageBarType={MessageBarType.error}>Could not load authorization configuration</MessageBar>
        );

    if (!isUserAuthenticated)
        return gracePeriodActive ? null : (
            <MessageBar messageBarType={MessageBarType.error}>
                User is unauthorized - Please log out and back in again
            </MessageBar>
        );

    if (!tenantName)
        return gracePeriodActive ? null : (
            <MessageBar messageBarType={MessageBarType.error}>
                No client selected - Please select a client and try again
            </MessageBar>
        );

    const headers = {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenantName,
    };

    return (
        <AuthorizationWrapper
            config={{
                ...clientConfiguration,
                defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
            }}
            jwt={token}
        >
            <AgGridLicenseProvider license={clientConfiguration.agGridLicense}>
                <LayerWrapper>
                    <PromptContextProvider>
                        <HistoryProvider value={{ browserHistory, hashHistory }}>
                            <DndProvider backend={HTML5Backend}>
                                <CustomRouter location={location} navigator={hashHistory}>
                                    <QueryClientProvider client={queryClient ?? _queryClient}>
                                        <ApiProvider
                                            value={{
                                                requestInit: {
                                                    headers,
                                                },
                                                LiftSiftApi: '/_apis/querybuilder',
                                                AggregatorApi: '/screenaggregator',
                                                TaskApi: '/taskgateway',
                                                ShowMessage: (msg: string): React.ReactText => toastError(msg),
                                            }}
                                        >
                                            <Router />
                                            <ToastMessageContainer
                                                className="single-spa-picklist"
                                                position="top-center"
                                                draggable
                                                pauseOnHover
                                                pauseOnFocusLoss
                                                autoClose={4000}
                                            />
                                        </ApiProvider>
                                    </QueryClientProvider>
                                </CustomRouter>
                            </DndProvider>
                        </HistoryProvider>
                    </PromptContextProvider>
                </LayerWrapper>
            </AgGridLicenseProvider>
        </AuthorizationWrapper>
    );
};

export default Root;
