// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picklist-crud-editor-panel-header {
    display: none;
}

.picklist-crud-editor-panel .ms-Panel-commands {
    display: none;
}

.picklist-crud-editor-panel .ms-Panel-content {
    padding: unset !important;
}

.picklist-crud-editor-panel .ms-Panel-scrollableContent, .picklist-field-crud-editor-panel .ms-Panel-content {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-picklist-core-virtual-8f0471469b/1/packages/core/lib/molecules/panels/ServerSidePicklistCrudEditorPanel/ServerSidePicklistCrudEditorPanel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".picklist-crud-editor-panel-header {\n    display: none;\n}\n\n.picklist-crud-editor-panel .ms-Panel-commands {\n    display: none;\n}\n\n.picklist-crud-editor-panel .ms-Panel-content {\n    padding: unset !important;\n}\n\n.picklist-crud-editor-panel .ms-Panel-scrollableContent, .picklist-field-crud-editor-panel .ms-Panel-content {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
