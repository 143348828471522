import * as React from 'react';
import useMutation from '../useMutation/useMutation';
import { ApiContext } from '../ApiContext';
import { deletePicklist } from '../api/Requests';
import { INDEXABLE_PICKLISTS_KEY } from '../useIndexablePicklists/useIndexablePicklists';
/**
 * Returns an array containing a method to save a PicklistItems[] for a given picklist and an object
 * representing the current state of the save request. Calling the save function
 * will invalidate the cache for any requests for a picklist with the same ID.
 * @param fieldConfiguration FieldConfiguration to be saved.
 */
// eslint-disable-next-line import/prefer-default-export
export const usePicklistDeleter = () => {
    const api = React.useContext(ApiContext);
    return useMutation(deletePicklist(api.AggregatorApi, api.requestInit), [
        INDEXABLE_PICKLISTS_KEY,
        api.AggregatorApi,
    ]);
};
