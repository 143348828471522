import React from 'react';
import { PicklistPage } from '@samc/picklist-core/lib/organisms/PicklistPage/PicklistPage';
import { EditingProvider } from '@samc/react-ui-grid';
import { HeaderContextProvider, RoutingWrapper } from '@samc/react-ui-history';
import { Entitlements, useCurrentUser } from '@samc/single-spa-authentication';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';

const PicklistManagerInner = (): React.ReactElement => {
    return (
        <EditingProvider>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <RoutingWrapper>
                    <PicklistPage />
                </RoutingWrapper>
            </div>
        </EditingProvider>
    );
};

export const PickListManager = (): React.ReactElement => {
    const user = useCurrentUser();

    if (!user.isLoaded) return <div />;

    if (!user.hasEntitlement(Entitlements.ConfigurationManagement.ManagePicklists))
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                title="You are not authorized to view this page"
                text="If you believe this is an error, please contact your administrator"
            />
        );

    return (
        <HeaderContextProvider initialValue={{ pageTitle: 'Picklists' }}>
            <PicklistManagerInner />
        </HeaderContextProvider>
    );
};

export default PickListManager;
