import RootIdValueGetter from '../../value-getters/RootIdValueGetter/RootIdValueGetter';
export const RootIdFieldConfig = () => {
    const getter = (params) => RootIdValueGetter(params);
    return {
        headerName: 'Root Id',
        field: 'ParentId', // this is required for the editor to work
        valueGetter: getter,
        width: 100,
        editableRule: 'false',
        requiredFieldRule: 'isnull([isSystemDefined]) or [isSystemDefined] != true',
    };
};
export default RootIdFieldConfig;
