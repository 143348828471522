import * as React from 'react';
/**
 * Allows for the short name and long name fields in the admin grid to display the
 * expected value when the item is a system defined item.
 * @param props
 * @param displayValue
 * @returns
 */
export const ParentIdRenderer = (props, displayValue) => {
    const { data, picklistField } = props;
    const item = data;
    const name = typeof picklistField !== 'function' && picklistField.id === 'longName' ? item.longName : item.shortName;
    const value = (item.isSystemDefined ? name : undefined);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, value !== null && value !== void 0 ? value : displayValue);
};
export default ParentIdRenderer;
