import * as React from 'react';
import useMutation from '../useMutation/useMutation';
import { savePicklistItems } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { PICKLIST_FIELDS_KEY } from '../usePicklistItems/usePicklistItems';
/**
 * Returns an array containing a method to save a PicklistItems[] for a given picklist and an object
 * representing the current state of the save request. Calling the save function
 * will invalidate the cache for any requests for a picklist with the same ID.
 * @param fieldConfiguration FieldConfiguration to be saved.
 */
export const usePicklistItemsSaver = (picklist) => {
    const { domainId, fieldConfigurationId } = picklist;
    const api = React.useContext(ApiContext);
    const handler = (items) => {
        if (!domainId || !fieldConfigurationId) {
            throw new Error('Cannot save a picklist without a domain id or field configuration id');
        }
        return savePicklistItems(api.TaskApi, domainId, fieldConfigurationId, api.requestInit)(items);
    };
    return useMutation(handler, [
        PICKLIST_FIELDS_KEY,
        api.LiftSiftApi,
        picklist.domainId || '',
    ]);
};
export default usePicklistItemsSaver;
