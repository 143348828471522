var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useRequestBase, getQueryKey } from '@samc/react-ui-request';
import { FunctionList, ValidateExpression } from '@samc/expressions-core';
import { getCombinedExpression } from '@samc/react-ui-core';
import { getPicklistItems, PICKLIST_ITEM_SORT_ORDER_EXPRESSION, getPascalCaseFieldName } from '../api/Requests';
import { ApiContext } from '../ApiContext';
import { PicklistDisplaySetting, PicklistSortType } from '../model/PicklistField';
import { CacheTime } from '../CacheTime';
import { useDomain } from '../useDomain/useDomain';
import { usePicklist } from '../usePicklist/usePicklist';
import { PICKLIST_API_KEY } from '../PicklistApiQueryKey';
export { getPascalCaseFieldName } from '../api/Requests'; // for backwards compatibility
export const PICKLIST_FIELDS_KEY = `${PICKLIST_API_KEY}picklistfields`;
export const getPicklistitemQueryFnDefaults = (params) => {
    const { filter, sortingData, offset, limit, additionalFields } = params, rest = __rest(params, ["filter", "sortingData", "offset", "limit", "additionalFields"]);
    const { order, orderBy } = sortingData !== null && sortingData !== void 0 ? sortingData : {};
    return Object.assign(Object.assign({}, rest), { filter, sortingData: {
            order: order !== null && order !== void 0 ? order : 'asc',
            orderBy: orderBy !== null && orderBy !== void 0 ? orderBy : {
                scalarExpression: PICKLIST_ITEM_SORT_ORDER_EXPRESSION,
            },
        }, offset: offset !== null && offset !== void 0 ? offset : 0, limit: limit !== null && limit !== void 0 ? limit : 1000, additionalFields: additionalFields !== null && additionalFields !== void 0 ? additionalFields : [] });
};
/**
 * Gets the react-query query key used to fetch picklist items
 */
export const getPicklistItemsQueryKey = (params) => getQueryKey(PICKLIST_FIELDS_KEY, [getPicklistitemQueryFnDefaults(params)]);
/**
 * Ensures an expression can be run on a domain.
 * @param expression the expression to validate
 * @param domain the domain on which the expression will be run
 * @param allowId whether id/Id references will be filtered out or not
 * @returns if that expression is valid on that domain
 */
export const expressionIsValidForDomain = (expression, domain) => {
    const { errors } = ValidateExpression(expression, FunctionList, domain.fields.filter((x) => expression.idAllowed || x.fieldName.toLowerCase() !== 'id').map((x) => x.fieldName));
    return errors.length === 0;
};
/**
 * Returns subset of given expressions which are valid for the provided domain
 * @param expressions expressions to test for domain validity
 * @param domain the domain against which expressions will be evaluated
 * @returns array of expressions valid for the domain
 */
export const getValidExpressions = (expressions, domain) => expressions.filter((exp) => expressionIsValidForDomain(exp, domain));
/**
 * Returns an expression string with expressions valid for the given domain, joined by " AND "
 * @param expressions expressions to test for validity and combine
 * @param domain the domain against which expressions will be evaluated
 * @returns unified expression containing valid expressions
 */
export const getUnifiedValidExpression = (expressions, domain) => {
    if (expressions === undefined || domain === undefined)
        return undefined;
    const validExpressions = getValidExpressions(expressions, domain);
    return validExpressions && validExpressions.length > 0 ? getCombinedExpression(validExpressions) : undefined;
};
const CustomShortNameField = 'customShortName';
const ShortNameField = 'shortName';
const CustomLongNameField = 'customLongName';
const LongNameField = 'longName';
export const getDisplayProperty = (displaySetting) => {
    switch (displaySetting) {
        case PicklistDisplaySetting.CustomId:
            return 'parentId';
        case PicklistDisplaySetting.LongName:
            return LongNameField;
        case PicklistDisplaySetting.CustomLongName:
            return CustomLongNameField;
        case PicklistDisplaySetting.ShortName:
            return ShortNameField;
        case PicklistDisplaySetting.CustomShortName:
            return CustomShortNameField;
        default:
            return 'id';
    }
};
/**
 * Gets an expression used to reference custom short name (defaulting back to short name) in an expression
 */
export const getCustomShortNameFieldExpression = () => `IIF(ISNULLOREMPTY([${getPascalCaseFieldName(CustomShortNameField)}]), [${getPascalCaseFieldName(ShortNameField)}], [${getPascalCaseFieldName(CustomShortNameField)}])`;
/**
 * Gets an expression used to reference custom long name (defaulting back to long name) in an expression
 */
export const getCustomLongNameFieldExpression = () => `IIF(ISNULLOREMPTY([${getPascalCaseFieldName(CustomLongNameField)}]), [${getPascalCaseFieldName(LongNameField)}], [${getPascalCaseFieldName(CustomLongNameField)}])`;
/**
 * Resolves an expression field reference on a picklist domain to a pascal
 * cased expression with proper handling of custom long/short name fields.
 */
export const getPicklistFieldExpression = (field) => {
    const asPascalCase = getPascalCaseFieldName(field);
    switch (asPascalCase) {
        case getPascalCaseFieldName(LongNameField):
        case getPascalCaseFieldName(CustomLongNameField):
            return getCustomLongNameFieldExpression();
        case getPascalCaseFieldName(ShortNameField):
        case getPascalCaseFieldName(CustomShortNameField):
            return getCustomShortNameFieldExpression();
        default:
            return `[${asPascalCase}]`;
    }
};
export const getOrderBy = (displayProperty, sort, customSortField, gridFieldConfiguration) => {
    switch (sort) {
        case PicklistSortType.Alphabetical: {
            if (Array.isArray(gridFieldConfiguration) && gridFieldConfiguration.length > 0) {
                const fieldNames = [];
                gridFieldConfiguration.forEach((fc) => {
                    if (fc.sortOrder)
                        fieldNames[fc.sortOrder] = getPicklistFieldExpression(fc.field);
                });
                const expressionFields = fieldNames.filter((e) => e !== undefined);
                if (expressionFields.length > 0)
                    return expressionFields.join('+');
            }
            return getPicklistFieldExpression(displayProperty);
        }
        case PicklistSortType.CustomField:
            if (customSortField)
                return getPicklistFieldExpression(customSortField);
            return PICKLIST_ITEM_SORT_ORDER_EXPRESSION;
        default:
            return PICKLIST_ITEM_SORT_ORDER_EXPRESSION;
    }
};
export const usePicklistItems = (picklistField, offset, limit, filters) => {
    const { picklistId, filterExpression } = picklistField;
    const api = React.useContext(ApiContext);
    const { data: picklist } = usePicklist(picklistId);
    const { data: domain } = useDomain(picklist === null || picklist === void 0 ? void 0 : picklist.domainId);
    const aggregatedFilters = React.useMemo(() => {
        if (!filterExpression)
            return filters;
        if (!filters)
            return [filterExpression];
        return filters.concat(filterExpression);
    }, [filterExpression, filters]);
    const picklistFilter = React.useMemo(() => getUnifiedValidExpression(aggregatedFilters, domain), [domain, aggregatedFilters]);
    // use picklists request instead of individual picklist for caching.
    const staleTime = React.useMemo(() => {
        if (picklist === null || picklist === void 0 ? void 0 : picklist.id) {
            const locked = picklist === null || picklist === void 0 ? void 0 : picklist.isLocked;
            // if the picklist is locked, this means 1 of 2 things:
            // 1. The picklist never changes (it's loaded and locked)
            // 2. The picklist is mapped back to a domain that users change outside of picklists
            // if it is not locked, set it to be longer so we don't do as many requests
            return locked ? CacheTime.picklistItemsShort : CacheTime.picklistItemsLong;
        }
        // default staleTime to be only 10 seconds
        return CacheTime.picklistItemsShort;
    }, [picklist === null || picklist === void 0 ? void 0 : picklist.id, picklist === null || picklist === void 0 ? void 0 : picklist.isLocked]);
    const query = ({ domainId: _domainId, filter: _filter, sortingData: _sortingData, offset: _offset, limit: _limit, additionalFields: _additionalFields, }) => getPicklistItems(api.LiftSiftApi, _domainId, api.requestInit, _filter, _sortingData, _offset, _limit, _additionalFields);
    const response = useRequestBase({
        key: PICKLIST_FIELDS_KEY,
        query,
        requestContext: ApiContext,
        params: [
            getPicklistitemQueryFnDefaults({
                domainId: (picklist === null || picklist === void 0 ? void 0 : picklist.domainId) || '',
                filter: picklistFilter,
                sortingData: {
                    order: 'asc',
                    orderBy: {
                        scalarExpression: getOrderBy(getDisplayProperty(picklistField.displaySetting), picklistField.sortType, picklistField.customSortField ? getDisplayProperty(picklistField.customSortField) : '', picklistField.gridFieldConfiguration),
                    },
                }, // sorting parameters
                offset,
                limit,
            }),
        ],
        options: {
            staleTime,
        },
    });
    return response;
};
