import { ShortNameFieldConfig } from '../../ShortNameFieldConfig/ShortNameFieldConfig';
import { LongNameFieldConfig } from '../../LongNameFieldConfig/LongNameFieldConfig';
import { DisplayExpressionFieldConfig } from '../../DisplayExpressionFieldConfig/DisplayExpressionFieldConfig';
import { CustomShortNameFieldConfig } from '../../CustomShortNameFieldConfig/CustomShortNameFieldConfig';
import { CustomLongNameFieldConfig } from '../../CustomLongNameFieldConfig/CustomLongNameFieldConfig';
import { SortOrderFieldConfig } from '../../SortOrderFieldConfig/SortOrderFieldConfig';
import { ActiveFlagFieldConfig } from '../../ActiveFlagFieldConfig/ActiveFlagFieldConfig';
import { IdFieldConfig } from '../../IdFieldConfig/IdFieldConfig';
import { RootIdFieldConfig } from '../../RootIdFieldConfig/RootIdFieldConfig';
import { transformPicklistGridFieldConfigurations, } from '../../../../hooks/usePicklistGridFields/usePicklistGridFields';
import { GridPicklistManager } from '../../../../utilities/GridPicklistManager/GridPicklistManager';
import { ParentIdRenderer } from '../../ParentIdRenderer';
export const PicklistConfigFields = (fetchItems, picklist, domains, isCustom, evaluate, readonly, isIdSettableOnAdd) => {
    if (!picklist)
        return undefined;
    const idField = IdFieldConfig(isCustom, isIdSettableOnAdd);
    const rootIdField = RootIdFieldConfig();
    const shortNameField = ShortNameFieldConfig(picklist, isCustom);
    const longNameField = LongNameFieldConfig(picklist, isCustom);
    const displayExpressionField = DisplayExpressionFieldConfig(domains, picklist.targetDomainIds);
    const customShortNameField = CustomShortNameFieldConfig(isCustom);
    const customLongNameField = CustomLongNameFieldConfig(isCustom);
    const sortOrderField = SortOrderFieldConfig();
    const activeFlagField = ActiveFlagFieldConfig();
    // do not show required on cells in the readonly view
    if (readonly) {
        rootIdField.requiredFieldRule = 'false';
        shortNameField.requiredFieldRule = 'false';
        longNameField.requiredFieldRule = 'false';
        customShortNameField.requiredFieldRule = 'false';
        customLongNameField.requiredFieldRule = 'false';
        sortOrderField.requiredFieldRule = 'false';
        activeFlagField.requiredFieldRule = 'false';
    }
    const inputFields = [
        idField,
        rootIdField,
        shortNameField,
        longNameField,
        displayExpressionField,
        customShortNameField,
        customLongNameField,
        sortOrderField,
        activeFlagField,
    ];
    const gridPicklist = new GridPicklistManager({
        picklistGetter: () => Promise.resolve(picklist),
        itemGetter: (_, params) => fetchItems(params),
    });
    return transformPicklistGridFieldConfigurations(inputFields, () => gridPicklist, evaluate, undefined, undefined, {
        parentId: ParentIdRenderer,
    });
};
export default PicklistConfigFields;
