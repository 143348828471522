// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picklist-grid-container {
    height: 100%;
    flex: 1;
}

.picklist-grid-container .picklist-grid {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.picklist-grid .ag-header-select-all, .picklist-grid .ag-checkbox-input-wrapper {
    display: none;
}

.picklist-grid .ag-header-cell:not(.ag-column-hover) .ag-header-cell-menu-button {
    display: none;
}`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-picklist-core-virtual-8f0471469b/1/packages/core/lib/grid/grids/PicklistGrid/PicklistGrid.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,OAAO;AACX;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".picklist-grid-container {\n    height: 100%;\n    flex: 1;\n}\n\n.picklist-grid-container .picklist-grid {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.picklist-grid .ag-header-select-all, .picklist-grid .ag-checkbox-input-wrapper {\n    display: none;\n}\n\n.picklist-grid .ag-header-cell:not(.ag-column-hover) .ag-header-cell-menu-button {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
