import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PickListManager } from '../../pages/PickListManager';

export const Router = (): React.ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<PickListManager />} />
        </Routes>
    );
};

export default Router;
