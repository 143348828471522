/**
 * Contains all of the keys on a PicklistItem object. Useful for UI-side expression
 * validation.
 */
export const AllPicklistItemKeys = [
    'customLongName',
    'customShortName',
    'displayExpression',
    'id',
    'isActive',
    'isSystemDefined',
    'longName',
    'parentId',
    'shortName',
    'sortOrder',
    '__ADDED__',
];
