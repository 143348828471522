/* eslint-disable react/display-name */
import React from 'react';
import { ExpressionCellEditor } from '../../editors/ExpressionCellEditor/ExpressionCellEditor';
const getEditor = (fields) => (params) => {
    return React.createElement(ExpressionCellEditor, { params: params, fields: fields });
};
export const DisplayExpressionFieldConfig = (domains, targetDomainIds) => {
    const getExpressionEditorFields = () => {
        const returnFields = [];
        if (domains) {
            domains
                .filter((d) => targetDomainIds === null || targetDomainIds === void 0 ? void 0 : targetDomainIds.includes(d.id))
                .forEach((d) => {
                const names = d.fields.map((f) => f.fieldName);
                returnFields.push(...names);
            });
        }
        return returnFields;
    };
    return {
        headerName: 'Display Rule (Expression)',
        field: 'displayExpression',
        type: 'string',
        editableRule: 'true',
        editor: getEditor(getExpressionEditorFields()),
        isPopupEditor: false,
        width: 250,
    };
};
export default DisplayExpressionFieldConfig;
