import React from 'react';
export const PicklistNameFieldConfig = (setPopoutPicklist) => {
    const Link = (props) => {
        const { value, data } = props;
        return (React.createElement("div", { "aria-hidden": true, onClick: () => setPopoutPicklist(data) }, value));
    };
    return {
        headerName: 'Picklist Name',
        field: 'name',
        type: 'string',
        cellStyle: { color: '#214d88', cursor: 'pointer' },
        width: 300,
        cellRenderer: Link,
    };
};
export default PicklistNameFieldConfig;
