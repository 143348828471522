import { PicklistType } from '@samc/picklist-api';
import { BoolValueGetter } from '../BoolValueGetter/BoolValueGetter';
export const CustomizedValueGetter = (params) => {
    const picklist = params.data;
    switch (picklist.type) {
        case PicklistType.Custom:
            return 'N/A';
        case PicklistType.Standard:
        default:
            if (picklist.isLocked)
                return 'N/A';
            return BoolValueGetter(params);
    }
};
export default CustomizedValueGetter;
