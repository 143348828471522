import * as React from 'react';
import { ExpressionField } from '@samc/expressions-react';
export const ExpressionCellEditor = (props) => {
    const { params, fields } = props;
    const { onChange: onChangeParam } = params;
    const [value, setValue] = React.useState(params.value || '');
    const onChange = React.useCallback((val) => {
        setValue(val);
        onChangeParam(val);
    }, [onChangeParam]);
    const updateGrid = () => params.onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => () => updateGrid(), []); // update value on unmount
    return (React.createElement(ExpressionField, { inline: true, allowEnter: true, ref: params.inputRef, expressionLanguage: "Centric", value: value, onChange: onChange, onBlur: updateGrid, fields: fields }));
};
export default ExpressionCellEditor;
