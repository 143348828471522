/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './PicklistConfigurationPanel.css';
import { DataEntryForm, InputType, LayoutType } from '@samc/react-ui-form';
const StatusItems = [
    {
        displayText: 'Active',
        id: 'Active',
    },
    {
        id: 'Inactive',
        displayText: 'Inactive',
    },
];
const getFormConfig = (domains, isNew) => ({
    title: 'Picklist Configuration Form',
    fields: {
        name: {
            field: 'name',
            inputType: InputType.Text,
            editableRule: isNew ? '[isLocked] <> true' : 'false',
            visibleRule: 'true',
            displayNameRule: "'Name'",
            requiredFieldRule: 'true',
        },
        status: {
            field: 'status',
            inputType: InputType.Select,
            menuItems: StatusItems,
            displayProperty: 'displayText',
            valueProperty: 'id',
            editableRule: '[isLocked] <> true',
            visibleRule: 'true',
            displayNameRule: "'Status'",
        },
        targetDomainIds: {
            field: 'targetDomainIds',
            inputType: InputType.MultiSelect,
            menuItems: domains,
            displayProperty: 'domainName',
            valueProperty: 'id',
            editableRule: '[isLocked] <> true',
            visibleRule: 'true',
            displayNameRule: "'Target Domains'",
        },
        description: {
            field: 'description',
            inputType: InputType.MultilineText,
            editableRule: '[isLocked] <> true',
            visibleRule: 'true',
            displayNameRule: "'Description'",
            requiredFieldRule: 'true',
        },
        isLocked: {
            field: 'isLocked',
            inputType: InputType.YesNoField,
            editableRule: '[isLocked] <> true',
            visibleRule: 'true',
            displayNameRule: "'Locked'",
        },
    },
    tabs: [
        {
            title: '',
            sections: [
                {
                    titleExpression: '',
                    fieldLayout: {
                        type: LayoutType.VerticalStack,
                        children: [
                            { children: [], field: 'name', type: LayoutType.HorizontalStack },
                            { children: [], field: 'status', type: LayoutType.HorizontalStack },
                        ],
                    },
                },
                {
                    titleExpression: '',
                    fieldLayout: {
                        type: LayoutType.VerticalStack,
                        children: [
                            { children: [], field: 'targetDomainIds', type: LayoutType.HorizontalStack },
                            { children: [], field: 'isLocked', type: LayoutType.HorizontalStack },
                        ],
                    },
                },
                {
                    titleExpression: '',
                    fieldLayout: {
                        type: LayoutType.VerticalStack,
                        children: [{ children: [], field: 'description', type: LayoutType.HorizontalStack }],
                    },
                },
            ],
            layout: {
                type: LayoutType.HorizontalStack,
                children: [
                    { children: [], index: 0, type: LayoutType.HorizontalStack },
                    { children: [], index: 1, type: LayoutType.HorizontalStack },
                    { children: [], index: 2, type: LayoutType.HorizontalStack },
                ],
            },
        },
    ],
    gridFields: [],
});
export const PicklistConfigurationPanel = (props) => {
    const { initialPicklist, isNew, onChange: onChangeProp, onSubmit, domains } = props;
    const formConfig = React.useMemo(() => getFormConfig(domains, isNew), [domains, isNew]);
    const onChange = React.useCallback((p) => {
        if (onChangeProp)
            onChangeProp(Object.assign(Object.assign({}, initialPicklist), p));
    }, [initialPicklist, onChangeProp]);
    return (React.createElement("div", { className: "picklist-configuration-panel" },
        React.createElement(DataEntryForm, { formConfig: formConfig, onSubmit: onSubmit, initialData: initialPicklist, onChange: onChange })));
};
export default PicklistConfigurationPanel;
