import { PicklistDisplaySetting, PicklistSetting } from '@samc/picklist-api';
/**
 * LongName field config for the admin grid
 * if Picklist.IsUserDefined=true
 *      then show longName as text and make it editable.
 * else
 *      if PicklistItem.IsSystemDefined
 *          show longName, as picklist, readonly
 *      else
 *          show parentId, as picklist, readonly
 * @param picklist
 * @param isCustom Picklist.IsUserDefined
 * @returns
 */
export const LongNameFieldConfig = (picklist, isCustom) => {
    return {
        headerName: 'Long Name',
        field: isCustom ? 'longName' : 'parentId',
        type: 'string',
        width: 200,
        editableRule: isCustom ? 'true' : 'false',
        requiredFieldRule: isCustom ? 'true' : 'false',
        filter: false,
        picklistField: isCustom
            ? undefined
            : {
                id: 'longName',
                setting: PicklistSetting.Single,
                picklistId: picklist.id,
                displaySetting: PicklistDisplaySetting.LongName, // longName resolution preferred
                valueSetting: PicklistDisplaySetting.Id,
                filterExpression: 'ISNULLOREMPTY([ParentId])', // only show root fields
            },
    };
};
export default LongNameFieldConfig;
