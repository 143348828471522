var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useQueryClient } from 'react-query';
import { getQueryKey, useRequestGetter } from '@samc/react-ui-request';
import { getDomain } from '../api/Requests';
import { CacheTime } from '../CacheTime';
import { useApiContext } from '../ApiContext';
import { PICKLIST_API_KEY } from '../PicklistApiQueryKey';
export const INDEXABLE_DOMAINS_KEY = `${PICKLIST_API_KEY}INDEXABLE_DOMAINS`;
export const DOMAIN_KEY = `${PICKLIST_API_KEY}domain`;
export const getDomainQueryKey = (id) => getQueryKey(DOMAIN_KEY, [id]);
export const useDomainGetter = () => {
    const { AggregatorApi, requestInit } = useApiContext();
    const queryClient = useQueryClient();
    const query = (_id) => __awaiter(void 0, void 0, void 0, function* () {
        const allItems = queryClient.getQueryData(INDEXABLE_DOMAINS_KEY, {
            stale: false,
        });
        if (allItems && allItems[_id])
            return allItems[_id];
        return getDomain(AggregatorApi, _id, requestInit);
    });
    const domainGetter = useRequestGetter({ key: DOMAIN_KEY, query, options: { staleTime: CacheTime.domain } });
    return domainGetter;
};
export default useDomainGetter;
