import { BoolValueGetter } from '../../value-getters/BoolValueGetter/BoolValueGetter';
import { BoolCellEditor } from '../../editors/BoolCellEditor/BoolCellEditor';
export const ActiveFlagFieldConfig = () => {
    return {
        headerName: 'Active Flag',
        field: 'isActive',
        width: 110,
        editableRule: 'true',
        valueGetter: BoolValueGetter,
        editor: BoolCellEditor,
    };
};
export default ActiveFlagFieldConfig;
