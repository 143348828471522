/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React from 'react';
import { ClassNameBuilder, GetValueFromData } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { useAdditionalContainerRegisterer } from '@samc/react-ui-grid';
import { PicklistWrapper } from '../../../atoms/controls/Picklist/Picklist';
import './PicklistCellEditor.css';
import { getPicklistField } from '../../types/PicklistFieldGetter';
export const PicklistCellEditor = (params) => {
    const { inputRef, data, picklistField: picklistFieldProp, onChange: onChangeProp, registerContainerGetter, unregisterContainerGetter, getPicklist, stopEditing, useGridFieldConfigurations, field, node, } = params;
    const [picklistRef] = useAdditionalContainerRegisterer(1, registerContainerGetter, unregisterContainerGetter, (v) => v.popoutElement);
    const [open, _setOpen] = React.useState(true);
    const [css] = useStyletron();
    const picklistField = React.useMemo(() => getPicklistField(picklistFieldProp, data, node), [data, node, picklistFieldProp]);
    const { renderAsToggle } = picklistField;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const gridPicklist = React.useMemo(() => getPicklist(picklistField), [picklistField]);
    const [value, setValue] = React.useState(GetValueFromData(data, field));
    const onChange = React.useCallback((val) => {
        onChangeProp(val, !!renderAsToggle);
        setValue(val);
    }, [onChangeProp, renderAsToggle]);
    // use an effect to stop editing to allow enough time for value to get to CellEditor before calling stopEditing
    React.useEffect(() => {
        if (!open)
            stopEditing(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    if (!gridPicklist)
        return null;
    return (React.createElement("div", { className: ClassNameBuilder('picklist-cell-editor', css({ maxHeight: '29px' })), ref: inputRef },
        React.createElement(PicklistWrapper, { value: value, onChange: onChange, toggleClassName: css({ height: '100% !important', padding: '4px' }), data: data, picklist: picklistField, fetchItems: gridPicklist.fetchItems, ref: picklistRef, openOnRender: true, onBlur: () => _setOpen(false), onOpen: () => _setOpen(true), enableToggleKeyboardListeners: true, useGridFieldConfigurations: useGridFieldConfigurations })));
};
export const getPicklistCellEditor = (picklistField, getPicklist, useGridFieldConfigurations) => (params) => {
    return (React.createElement(PicklistCellEditor, Object.assign({ useGridFieldConfigurations: useGridFieldConfigurations, getPicklist: getPicklist, picklistField: picklistField }, params)));
};
export default PicklistCellEditor;
