import * as React from 'react';
import { PicklistConfigurationPanel } from '../../panels/PicklistConfigurationPanel/PicklistConfigurationPanel';
import { PicklistResultGrid } from '../../../grid/grids/PicklistResultGrid/PicklistResultGrid';
import './PicklistConfigurationBody.css';
const PicklistConfigurationBody = (props) => {
    const { initialPicklist, onChangePicklist: onChangePicklistInner, fetchItems, onChangeItem, onSubmit, domains, isNew, containerRef, } = props;
    const [picklist, setPicklist] = React.useState(initialPicklist);
    const onChangePicklist = React.useCallback((pl) => {
        setPicklist(pl);
        onChangePicklistInner(pl);
    }, [onChangePicklistInner]);
    const onChangePicklistItem = React.useCallback((args) => {
        onChangePicklist(Object.assign(Object.assign({}, picklist), { lastUpdated: new Date() }));
        onChangeItem === null || onChangeItem === void 0 ? void 0 : onChangeItem(args);
    }, [onChangePicklist, onChangeItem, picklist]);
    React.useEffect(() => {
        setPicklist(initialPicklist);
    }, [initialPicklist]);
    return (React.createElement("div", { ref: containerRef, className: "picklist-config-body" },
        React.createElement(PicklistConfigurationPanel, { onSubmit: onSubmit, initialPicklist: initialPicklist, onChange: onChangePicklist, isNew: isNew, domains: domains }),
        React.createElement("div", { style: { height: 'calc(100vh - 200px)' } },
            React.createElement(PicklistResultGrid, { onSubmit: onSubmit, fetchItems: fetchItems, domains: domains, picklist: picklist, onChangeData: onChangePicklistItem, readonly: picklist.isLocked }))));
};
export default PicklistConfigurationBody;
