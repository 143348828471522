/* eslint-disable react/display-name */
import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import './ServerSidePicklistCrudEditorPanel.css';
import ServerSidePicklistCrudEditor from '../../editors/PicklistCrudEditor/ServerSidePicklistCrudEditor';
export const ServerSidePicklistCrudEditorPanel = (props) => {
    const { picklist, isOpen, onClose, onUpdate } = props;
    return (React.createElement(Panel, { isOpen: isOpen, type: PanelType.large, closeButtonAriaLabel: "Close", hasCloseButton: false, className: "picklist-crud-editor-panel", headerClassName: "picklist-crud-editor-panel-header", layerProps: { eventBubblingEnabled: true } },
        React.createElement("div", { style: { height: '100%', width: '100%' } },
            React.createElement(ServerSidePicklistCrudEditor, { onClose: onClose, picklist: picklist, onUpdate: onUpdate }))));
};
export default ServerSidePicklistCrudEditorPanel;
