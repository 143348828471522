import * as React from 'react';
import { DropDownCellEditor } from '@samc/react-ui-grid';
export const BoolCellEditor = (params) => {
    const menuItems = [
        { display: 'Yes', value: true },
        { display: 'No', value: false },
    ];
    return React.createElement(DropDownCellEditor, { menuItems: menuItems, params: params, displayProperty: "display", valueProperty: "value" });
};
export default BoolCellEditor;
