import { PicklistType } from '@samc/picklist-api';
export const TypeFieldConfig = () => {
    return {
        headerName: 'Type',
        field: 'type',
        type: 'string',
        width: 172,
        valueGetter: (params) => PicklistType[params.data.type],
    };
};
export default TypeFieldConfig;
