// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picklist-result-grid-container {
    height: 100%;
    padding: 12px;
    flex: 1;
}

.picklist-result-grid-container .picklist-result-grid {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.picklist-result-grid .grid-full-screen{
    position: fixed;
    top: 40px;
    left: 0px;
    width: 100%;
    height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-picklist-core-virtual-8f0471469b/1/packages/core/lib/grid/grids/PicklistResultGrid/PicklistResultGrid.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,OAAO;AACX;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,SAAS;IACT,SAAS;IACT,WAAW;IACX,aAAa;AACjB","sourcesContent":[".picklist-result-grid-container {\n    height: 100%;\n    padding: 12px;\n    flex: 1;\n}\n\n.picklist-result-grid-container .picklist-result-grid {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.picklist-result-grid .grid-full-screen{\n    position: fixed;\n    top: 40px;\n    left: 0px;\n    width: 100%;\n    height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
