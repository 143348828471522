import { PicklistManager } from '../PicklistManager/PicklistManager';
export class GridPicklistManager extends PicklistManager {
    constructor() {
        super(...arguments);
        /**
         * Nodes whose value setters are currently pending an async outcome
         */
        this._pendingValueSetterNodes = new Set();
        this._valueSetterNodeListeners = new Map();
        this.setValueSetterPending = (node, isPending) => {
            if (isPending)
                this._pendingValueSetterNodes.add(node);
            else
                this._pendingValueSetterNodes.delete(node);
            const listeners = this._valueSetterNodeListeners.get(node);
            if (listeners)
                listeners.forEach((l) => l({ node, isPending }));
        };
        this.getValueSetterPending = (node) => this._pendingValueSetterNodes.has(node);
        this.addValueSetterListener = (node, listener) => {
            let listenerSet = this._valueSetterNodeListeners.get(node);
            if (!listenerSet) {
                listenerSet = new Set();
                this._valueSetterNodeListeners.set(node, listenerSet);
            }
            listenerSet.add(listener);
        };
        this.removeValueSetterListener = (node, listener) => {
            const listenerSet = this._valueSetterNodeListeners.get(node);
            if (!listenerSet)
                return;
            listenerSet.delete(listener);
            if (!listenerSet.size)
                this._valueSetterNodeListeners.delete(node);
        };
    }
}
export default GridPicklistManager;
