import React from 'react';
import { Grid } from '@samc/react-ui-grid';
import { PicklistPageFieldConfigurations } from '../../field-configs/collections/PicklistPageFieldConfigurations/PicklistPageFieldConfigurations';
import './PicklistGrid.css';
const noop = () => undefined;
const PicklistGrid = (props) => {
    const { picklists, onEdit, onDelete } = props;
    const fields = React.useMemo(() => PicklistPageFieldConfigurations(onEdit), [onEdit]);
    const getContextMenuItems = React.useCallback((params) => {
        var _a;
        const picklist = (_a = params.node) === null || _a === void 0 ? void 0 : _a.data;
        if (!picklist)
            return [];
        // to delete a picklist it must be custom, inactive, not locked
        if (!picklist.domainId &&
            picklist.isUserDefined &&
            picklist.status === 'Inactive' &&
            !picklist.isLocked &&
            onDelete)
            return [
                {
                    name: 'Delete',
                    action: () => onDelete(picklist.id),
                },
            ];
        return [];
    }, [onDelete]);
    return (React.createElement("div", { className: "picklist-grid-container" },
        React.createElement("div", { className: "picklist-grid" },
            React.createElement(Grid, { onSubmit: noop, fields: fields, data: picklists, suppressEditToggle: true, suppressDeleteButton: true, suppressClearFilter: true, sizeColumnsToFit: true, getContextMenuItems: getContextMenuItems, rowsPerPage: 1000, stopEditingWhenCellsLoseFocus: true }))));
};
export default PicklistGrid;
