import { evaluate, ExpressionParserVisitor, isCaseNode, isExpressionNode, isFunctionNode, isLeaf, NodeToString, } from '@samc/expressions-core';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PartialExpressionEvaluator {
    constructor(evaluateMethod) {
        // eslint-disable-next-line class-methods-use-this
        this.getMissingFields = (data, expression) => {
            // eslint-disable-next-line @typescript-eslint/ban-types
            const fields = Object.keys((data !== null && data !== void 0 ? data : {}));
            const missingFields = new Set();
            const fieldMatches = expression.matchAll(/\[([^[]+)\]/g);
            let curMatch = fieldMatches.next().value;
            while (curMatch) {
                const field = curMatch[1];
                if (!fields.includes(field))
                    missingFields.add(field);
                curMatch = fieldMatches.next().value;
            }
            return Array.from(missingFields);
        };
        this.evaluateMissingFieldSubExpressionsToTrue = (expression, missingFields) => {
            const visitor = new ExpressionParserVisitor();
            const rootExpression = visitor.parseExpression(expression);
            return NodeToString(this.removeMissingFields(rootExpression, missingFields));
        };
        this.removeMissingFields = (expressionNode, missingFields) => {
            if (isLeaf(expressionNode)) {
                if (missingFields.some((mf) => expressionNode === `[${mf}]`))
                    return 'true';
                return expressionNode;
            }
            if (isFunctionNode(expressionNode)) {
                return Object.assign(Object.assign({}, expressionNode), { parameters: expressionNode.parameters.map((p) => this.removeMissingFields(p, missingFields)) });
            }
            if (isExpressionNode(expressionNode)) {
                if (missingFields.some((mf) => expressionNode.leftOperand === `[${mf}]` || expressionNode.rightOperand === `[${mf}]`))
                    return 'true';
                return Object.assign(Object.assign({}, expressionNode), { leftOperand: this.removeMissingFields(expressionNode.leftOperand, missingFields), rightOperand: expressionNode.rightOperand
                        ? this.removeMissingFieldsFromNodeArray(expressionNode.rightOperand, missingFields)
                        : undefined });
            }
            if (isCaseNode(expressionNode)) {
                return {
                    case: this.removeMissingFields(expressionNode.case, missingFields),
                    tests: expressionNode.tests.map((t) => this.removeMissingFields(t, missingFields)),
                    outputs: expressionNode.outputs.map((o) => this.removeMissingFields(o, missingFields)),
                    default: expressionNode.default
                        ? this.removeMissingFields(expressionNode.default, missingFields)
                        : undefined,
                };
            }
            throw new Error(`Unknown node type, ${expressionNode}`);
        };
        this.removeMissingFieldsFromNodeArray = (expressionNodes, missingFields) => {
            if (!Array.isArray(expressionNodes)) {
                return this.removeMissingFields(expressionNodes, missingFields);
            }
            const ret = [];
            expressionNodes.forEach((n) => {
                ret.push(this.removeMissingFields(n, missingFields));
            });
            return ret;
        };
        /**
         * Converts the expression to a partialized expression by omitting missing fields and replacing with true
         */
        this.partialize = (data, expression) => {
            const missingFields = this.getMissingFields(data, expression);
            if (missingFields.length === 0)
                return expression;
            return this.evaluateMissingFieldSubExpressionsToTrue(expression, missingFields);
        };
        this.evaluate = (data, expression) => {
            const missingFields = this.getMissingFields(data, expression);
            if (missingFields.length === 0)
                return this._evaluate(data, expression, true);
            const newExpression = this.evaluateMissingFieldSubExpressionsToTrue(expression, missingFields);
            return this._evaluate(data, newExpression, true);
        };
        if (!evaluateMethod)
            this._evaluate = evaluate;
        else
            this._evaluate = evaluateMethod;
    }
}
export default PartialExpressionEvaluator;
