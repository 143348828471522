import { PicklistDisplaySetting, PicklistSetting } from '@samc/picklist-api';
/**
 * LongName field config for the admin grid
 * if Picklist.IsUserDefined=true
 *      then show shortName as text and make it editable.
 * else
 *      if PicklistItem.IsSystemDefined
 *          show shortName, as picklist, readonly
 *      else
 *          show parentId, as picklist, editable for user to select parent
 * @param picklist
 * @param isCustom Picklist.IsUserDefined
 * @returns
 */
export const ShortNameFieldConfig = (picklist, isCustom) => {
    return {
        headerName: 'Short Name',
        field: isCustom ? 'shortName' : 'parentId', // this is required for the editor to work
        width: 150,
        editableRule: 'isnull([isSystemDefined]) or [isSystemDefined] != true',
        requiredFieldRule: 'isnull([isSystemDefined]) or [isSystemDefined] != true',
        picklistField: isCustom
            ? undefined
            : {
                id: 'shortName',
                setting: PicklistSetting.Single,
                picklistId: picklist.id,
                displaySetting: PicklistDisplaySetting.ShortName, // shortName resolution preferred
                valueSetting: PicklistDisplaySetting.Id,
                filterExpression: 'ISNULLOREMPTY([ParentId]) || [Id] != [ParentId]', // only show root fields
            },
    };
};
export default ShortNameFieldConfig;
