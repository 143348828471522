import { BoolValueGetter } from '../../value-getters/BoolValueGetter/BoolValueGetter';
export const SystemLockedFieldConfig = () => {
    return {
        headerName: 'System Locked',
        field: 'isLocked',
        valueGetter: BoolValueGetter,
        width: 225,
    };
};
export default SystemLockedFieldConfig;
