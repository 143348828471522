var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getQueryKey, useRequestGetter } from '@samc/react-ui-request';
import { useQueryClient } from 'react-query';
import { getPicklist } from '../api/Requests';
import { CacheTime } from '../CacheTime';
import { useApiContext } from '../ApiContext';
import { PICKLIST_API_KEY } from '../PicklistApiQueryKey';
export const INDEXABLE_PICKLISTS_KEY = `${PICKLIST_API_KEY}INDEXABLE_PICKLISTS`;
export const PICKLIST_KEY = `${PICKLIST_API_KEY}picklist`;
export const getPicklistQueryKey = (id) => getQueryKey(PICKLIST_KEY, [id]);
export const usePicklistGetter = () => {
    const api = useApiContext();
    const queryClient = useQueryClient();
    const query = (_id) => __awaiter(void 0, void 0, void 0, function* () {
        const allPicklistItems = queryClient.getQueryData(INDEXABLE_PICKLISTS_KEY, {
            stale: false,
        });
        if (allPicklistItems && allPicklistItems[_id])
            return allPicklistItems[_id];
        return getPicklist(api.AggregatorApi, _id, api.requestInit);
    });
    const picklistGetter = useRequestGetter({ key: PICKLIST_KEY, query, options: { staleTime: CacheTime.picklist } });
    return picklistGetter;
};
export default usePicklistGetter;
