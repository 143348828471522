// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picklist-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.picklist-tabs .action-button {
    margin-left: auto;
    align-self: center;
}

/* Solves issues with toasts appearing behind flyouts, flyouts have a z index of 1000000 */
.Toastify__toast-container {
    z-index: 1000001 !important;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-picklist-core-virtual-8f0471469b/1/packages/core/lib/organisms/PicklistPage/PicklistPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA,0FAA0F;AAC1F;IACI,2BAA2B;AAC/B","sourcesContent":[".picklist-body {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n}\n\n.picklist-tabs .action-button {\n    margin-left: auto;\n    align-self: center;\n}\n\n/* Solves issues with toasts appearing behind flyouts, flyouts have a z index of 1000000 */\n.Toastify__toast-container {\n    z-index: 1000001 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
