// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picklist-floating-filter .searchable-dropdown {
    height: 18px !important;
}
.picklist-floating-filter .multicolumn-picklist {
    height: 18px !important;
}

.picklist-floating-filter .ms-TooltipHost {
    width: 0px;
    overflow: visible !important;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-picklist-core-virtual-8f0471469b/1/packages/core/lib/grid/filters/PicklistColumnFilter/PicklistColumnFloatingFilter.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,4BAA4B;AAChC","sourcesContent":[".picklist-floating-filter .searchable-dropdown {\n    height: 18px !important;\n}\n.picklist-floating-filter .multicolumn-picklist {\n    height: 18px !important;\n}\n\n.picklist-floating-filter .ms-TooltipHost {\n    width: 0px;\n    overflow: visible !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
