// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picklist-configuration-panel {
    display: flex;
    justify-content: left;
    align-items: center;
    width: calc(100% - 12px);
    width: fit-content;
    font-family: Roboto;
    padding-left: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.picklist-configuration-panel div:empty {
    min-height: unset !important;
}`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-picklist-core-virtual-8f0471469b/1/packages/core/lib/molecules/panels/PicklistConfigurationPanel/PicklistConfigurationPanel.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');\n\n.picklist-configuration-panel {\n    display: flex;\n    justify-content: left;\n    align-items: center;\n    width: calc(100% - 12px);\n    width: fit-content;\n    font-family: Roboto;\n    padding-left: 12px;\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\n.picklist-configuration-panel div:empty {\n    min-height: unset !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
