var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Spinner } from '@fluentui/react';
import { PicklistSetting, getDisplayProperty, } from '@samc/picklist-api';
import { ConvertGridFilterToFilterExpression, GridDropdown, useAgGridApi, } from '@samc/react-ui-grid';
import { ExpressionHandler, isNullOrUndefined, useRefWrapper, } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
import { getFilterModelForPicklist, HandleChangeGrid, withNullable } from './PicklistFunctions';
const CUSTOM_LONG_NAME_FIELD = 'customLongName';
const CUSTOM_SHORT_NAME_FIELD = 'customShortName';
const NO_FIELDS = [];
export const DEFAULT_FIELDS = [
    {
        field: CUSTOM_LONG_NAME_FIELD,
        headerName: 'Long Name',
    },
    {
        field: CUSTOM_SHORT_NAME_FIELD,
        headerName: 'Short Name',
    },
];
const _GridPicklist = (props, ref) => {
    var _a, _b;
    const { fetchItems, selectedKeys: selectedKeysProp, onChange, disabled, valueProperty, displayProperty, picklist: picklistField, overrideTooltip, isUpdated, isRequired, hasError, fillCellColor, openOnRender, useGridFieldConfigurations, filterObject, } = props;
    const [gridApi, onGridReady, columnApi] = useAgGridApi();
    const gridApiRef = useRefWrapper(gridApi);
    const columnApiRef = useRefWrapper(columnApi);
    const hardReloadPending = React.useRef(false);
    const displayExprResults = React.useRef(new Map());
    const selectedKeys = React.useMemo(() => selectedKeysProp !== null && selectedKeysProp !== void 0 ? selectedKeysProp : [], [selectedKeysProp]);
    /**
     * All lowercase; Always strings
     */
    const selectedKeysSet = React.useMemo(() => {
        if (!selectedKeys)
            return new Set();
        return new Set(selectedKeys.map((k) => String(k).toLowerCase()));
    }, [selectedKeys]);
    const isKeySelected = React.useCallback((key) => {
        return selectedKeysSet.has(String(key).toLowerCase());
    }, [selectedKeysSet]);
    const isRowHidden = (picklistItem) => {
        if (!picklistItem)
            return true;
        const { isActive, hidden } = picklistItem;
        const id = picklistItem[valueProperty];
        if (isNullOrUndefined(id))
            return true;
        if (isKeySelected(id))
            return false; // selected keys should always be visible
        if (!isActive || hidden)
            return true;
        const expressionResult = displayExprResults.current.get(id);
        if (expressionResult === false)
            return true;
        return false;
    };
    const evaluator = useEvaluator(undefined, true);
    const expressionHandler = React.useRef(new ExpressionHandler({
        evaluator,
        initialExpressionData: filterObject,
        initialGlobalHandler: (ev) => {
            const { optionEvents } = ev;
            if (!optionEvents.length)
                return;
            optionEvents.forEach((e) => {
                var _a;
                const { evaluatedExpression, optionId } = e;
                displayExprResults.current.set(optionId, evaluatedExpression !== false);
                const _gridApi = gridApiRef.current;
                const _columnApi = columnApiRef.current;
                if (_gridApi && _columnApi) {
                    const nodesToReload = [];
                    const rowNode = _gridApi.getRowNode(String(optionId).toLowerCase()); // this is how the GridDropdown does it
                    if (rowNode) {
                        const { data, selectable } = rowNode;
                        const newIsSelectable = !isRowHidden(data);
                        if (newIsSelectable !== selectable) {
                            // we have to do this manually because AgGrid is not smart about re-evaluating isRowSelectable
                            rowNode.setRowSelectable(!isRowHidden(data));
                            nodesToReload.push(rowNode);
                        }
                    }
                    // must redraw to reflect change
                    const selectorColumn = (_a = _columnApi.getColumns()) === null || _a === void 0 ? void 0 : _a.at(0);
                    _gridApi.refreshCells(Object.assign(Object.assign({}, (selectorColumn && {
                        columns: [selectorColumn],
                    })), { rowNodes: nodesToReload }));
                }
            });
        },
    }));
    const { listViewId, optionalDisplaySetting } = picklistField;
    const dropdownRef = React.useRef(null);
    const handleChangeGrid = React.useCallback((ev) => {
        const { selectedOptions } = ev;
        HandleChangeGrid(valueProperty, selectedOptions.map((o) => o.data), onChange);
    }, [onChange, valueProperty]);
    const { fields, frameworkComponents, domainViewMembers, isLoading } = (_a = useGridFieldConfigurations === null || useGridFieldConfigurations === void 0 ? void 0 : useGridFieldConfigurations({
        picklistField,
    })) !== null && _a !== void 0 ? _a : {
        fields: (_b = picklistField.gridFieldConfiguration) !== null && _b !== void 0 ? _b : NO_FIELDS,
    };
    const getOptions = (params) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        const { filter, offset } = params;
        const { limit, filterModel, sortModel } = filter !== null && filter !== void 0 ? filter : {};
        const { sort: order, colId: orderBy } = (_c = sortModel === null || sortModel === void 0 ? void 0 : sortModel.at(0)) !== null && _c !== void 0 ? _c : {};
        const filters = ['[IsActive] = true'];
        let sortOverride;
        if (orderBy) {
            sortOverride = {
                order,
                orderBy: {
                    scalarExpression: `[${orderBy.charAt(0).toUpperCase() + orderBy.slice(1)}]`,
                },
            };
        }
        // eslint-disable-next-line @typescript-eslint/ban-types
        if (Object.keys((filterModel !== null && filterModel !== void 0 ? filterModel : {})).length) {
            const _filterModel = getFilterModelForPicklist(filterModel, (picklistField === null || picklistField === void 0 ? void 0 : picklistField.gridFieldConfiguration) || [] || []);
            const filterExpression = ConvertGridFilterToFilterExpression(_filterModel, (picklistField === null || picklistField === void 0 ? void 0 : picklistField.gridFieldConfiguration) || []);
            if (filterExpression)
                filters.push(filterExpression);
        }
        const hardReload = hardReloadPending.current;
        hardReloadPending.current = false;
        const { items, totalCount } = yield fetchItems({
            filters,
            sortOverride,
            offset: offset || 0,
            limit,
            hardReload,
            additionalFields: domainViewMembers,
        });
        yield expressionHandler.current.registerOptionExpressions(...items.map((item) => ({
            managedExpressions: {
                displayExpression: item.displayExpression,
            },
            optionId: item[valueProperty],
            emitInitialEvents: true,
        })));
        const options = items.map((i) => ({
            data: i,
            displayText: String(i[displayProperty]),
            id: i[valueProperty],
        }));
        return { options, totalCount };
    });
    const resolveSelection = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const { items } = yield fetchItems({ ids });
        return items.map((i) => ({
            id: i[valueProperty],
            displayText: String(i[displayProperty]),
            data: i,
        }));
    });
    const getSelectionText = (params) => {
        const { selectedOptions } = params;
        if (!optionalDisplaySetting)
            return selectedOptions.map((o) => o.displayText).join(', ');
        const optionalDisplayProperty = getDisplayProperty(optionalDisplaySetting);
        return selectedOptions
            .map((o) => {
            const { displayText, data } = o;
            if (!data)
                return displayText;
            const optionalDisplayText = data[optionalDisplayProperty];
            if (isNullOrUndefined(optionalDisplayText))
                return displayText;
            return `${displayText} (${optionalDisplayText})`;
        })
            .join('; ');
    };
    // ensure expression handler has newest evaluator
    React.useEffect(() => {
        expressionHandler.current.setEvaluator(evaluator);
    }, [evaluator]);
    // ensure expression handler has newest data
    React.useEffect(() => {
        if (filterObject)
            expressionHandler.current.updateExpressionData(filterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);
    React.useImperativeHandle(ref, () => {
        return {
            getSelectedItems: () => {
                var _a, _b;
                const options = (_b = (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.selectedOptions.map((o) => o.data)) !== null && _b !== void 0 ? _b : [];
                return options.filter((o) => !!o);
            },
            focusInput: (v) => withNullable(dropdownRef.current, (dr) => dr.focusInput(v), undefined),
            reload: () => withNullable(dropdownRef.current, (dr) => {
                dr.reload();
                hardReloadPending.current = true;
            }, undefined),
            get popoutElement() {
                return withNullable(dropdownRef.current, (dr) => dr.popoutElement, null);
            },
        };
    }, []);
    const doHardReload = React.useCallback(() => {
        hardReloadPending.current = true;
    }, []);
    if (isLoading)
        return React.createElement(Spinner, null);
    return (React.createElement(GridDropdown, { className: "multicolumn-picklist", ref: dropdownRef, fields: fields.length === 0 ? DEFAULT_FIELDS : fields, frameworkComponents: frameworkComponents, getOptions: getOptions, onChange: handleChangeGrid, multiSelect: picklistField.setting === PicklistSetting.MultiGridSelectInput, overrideTooltip: overrideTooltip, isUpdated: isUpdated, hasError: hasError, openOnRender: openOnRender, selectedKeys: selectedKeys, isRowSelectable: ({ data }) => !isRowHidden(data), suppressSizeColumnsToFit: !!listViewId, selectRowOnClick: true, resolveSelection: resolveSelection, overrideText: getSelectionText, disabled: disabled, isRequired: isRequired, onGridReady: onGridReady, fillCellColor: fillCellColor, onRefresh: doHardReload }));
};
export const GridPicklist = React.forwardRef(_GridPicklist);
export default GridPicklist;
