import * as React from 'react';
import { useDomains } from '@samc/picklist-api/lib/useDomains/useDomains';
import PicklistConfigurationBody from './PicklistConfigurationBody';
import { ExtractDomains } from './ServerSidePicklistConfigurationBodyFunctions';
const ServerSidePicklistConfigurationBody = (props) => {
    const { initialPicklist, onSubmit, containerRef, onChangePicklist, fetchItems, onChangeItem, isNew } = props;
    const domainResult = useDomains();
    const domains = React.useMemo(() => ExtractDomains(domainResult), [domainResult]);
    return (React.createElement(PicklistConfigurationBody, { onSubmit: onSubmit, initialPicklist: initialPicklist, onChangePicklist: onChangePicklist, fetchItems: fetchItems, onChangeItem: onChangeItem, domains: domains, isNew: isNew, containerRef: containerRef }));
};
export default ServerSidePicklistConfigurationBody;
