export const CustomShortNameFieldConfig = (isCustom) => {
    return {
        headerName: 'Custom Short Name',
        field: 'customShortName',
        type: 'string',
        editableRule: 'true',
        requiredFieldRule: isCustom ? 'false' : 'isnull([isSystemDefined]) or [isSystemDefined] != true',
        width: 200,
    };
};
export default CustomShortNameFieldConfig;
