import { CustomizedValueGetter } from '../../value-getters/CustomizedValueGetter/CustomizedValueGetter';
export const CustomizedFieldConfig = () => {
    return {
        headerName: 'Customized',
        field: 'isCustomized',
        valueGetter: CustomizedValueGetter,
        width: 110,
    };
};
export default CustomizedFieldConfig;
