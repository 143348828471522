/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
/* eslint-disable eqeqeq */
import * as React from 'react';
import { CustomTooltip } from '@samc/react-ui-grid/lib/organisms/BaseGrid/CustomTooltip/CustomTooltip';
import { getTooltipValue } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { GetValueFromData } from '@samc/react-ui-core';
import { FlattenedToArray } from '../../../atoms/controls/Picklist/PicklistFunctions';
import { getGridDisplayValue } from './Utils';
import { getPicklistField } from '../../types/PicklistFieldGetter';
const _PicklistCustomTooltip = (props, ref) => {
    var _a;
    const { data, colDef, tooltipComponentParams, node, location, field } = props;
    const { getPicklist, picklistField: picklistFieldGetter } = tooltipComponentParams;
    const value = ((_a = GetValueFromData(data, field)) !== null && _a !== void 0 ? _a : '');
    const headerTooltip = colDef === null || colDef === void 0 ? void 0 : colDef.headerName;
    const picklistField = React.useMemo(() => getPicklistField(picklistFieldGetter, data, node), [data, node, picklistFieldGetter]);
    const [gridPicklist] = React.useState(getPicklist(picklistField));
    const displayValue = React.useMemo(() => {
        if (location !== 'cell')
            return value;
        const selectedValues = FlattenedToArray(String(value));
        return getTooltipValue(getGridDisplayValue(picklistField, selectedValues, (id) => gridPicklist === null || gridPicklist === void 0 ? void 0 : gridPicklist.getCachedItem(id)), headerTooltip);
    }, [gridPicklist, headerTooltip, location, picklistField, value]);
    return React.createElement(CustomTooltip, Object.assign({}, props, { value: displayValue, ref: ref }));
};
export const PicklistCustomTooltip = React.forwardRef(_PicklistCustomTooltip);
export default PicklistCustomTooltip;
