import React from 'react';
import { getDisplayProperty } from '@samc/picklist-api';
import { GetValueFromData } from '@samc/react-ui-core';
import { useGridFilter } from 'ag-grid-react';
import { useStyletron } from 'styletron-react';
import { Picklist } from '../../../atoms/controls/Picklist/Picklist';
const EMPTY_VALUES = [];
export const PicklistColumnFilter = (props) => {
    const { getPicklist, picklistField, onModelChange, model } = props;
    const { colDef } = props;
    const { field } = colDef;
    const { typedValues: values } = model !== null && model !== void 0 ? model : { typedValues: EMPTY_VALUES };
    const [renderContents, setRenderContents] = React.useState(false);
    const [css] = useStyletron();
    /**
     * Lowercase string values
     */
    const valueSet = React.useMemo(() => new Set(values.map((v) => String(v).toLowerCase())), [values]);
    const onChange = (newValues) => {
        var _a;
        if (newValues.length === 0)
            onModelChange(null);
        else {
            onModelChange({ typedValues: newValues, values: (_a = values.map(String)) !== null && _a !== void 0 ? _a : [], filterType: 'set' });
        }
    };
    // sync state with grid
    useGridFilter({
        doesFilterPass: (p) => {
            const cellValues = String(GetValueFromData(p.data, field))
                .toLowerCase()
                .split(',')
                .map((v) => v.trim());
            if (cellValues.some((cellValue) => valueSet.has(String(cellValue).toLowerCase())))
                return true;
            return false;
        },
        afterGuiAttached: () => setRenderContents(true), // we do this to ensure that the GUI is mounted before registering scroll listeners
        afterGuiDetached: () => setRenderContents(false),
    });
    // this component absolutely cannot return null or an empty fragment, because AgGrid secretly will just stop working if you do...
    if (!renderContents)
        return React.createElement("div", null, "Loading...");
    const fetchItems = (p) => {
        const gridPicklist = getPicklist(picklistField);
        if (!gridPicklist) {
            // eslint-disable-next-line no-console
            console.warn('Picklist configuration not found');
            return Promise.resolve({ items: [], totalCount: 0 });
        }
        return gridPicklist.fetchItems(p);
    };
    return (React.createElement(Picklist, { fetchItems: fetchItems, bodyOnly: true, valueProperty: "id", displayProperty: getDisplayProperty(picklistField.displaySetting), onChange: onChange, selectedKey: values, showCheckboxes: true, bodyClassName: css({ maxHeight: '400px !important' }) }));
};
export default PicklistColumnFilter;
