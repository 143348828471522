import { PicklistNameFieldConfig } from '../../PicklistNameFieldConfig/PicklistNameFieldConfig';
import { IdFieldConfig } from '../../IdFieldConfig/IdFieldConfig';
import { TypeFieldConfig } from '../../TypeFieldConfig/TypeFieldConfig';
import { SystemLockedFieldConfig } from '../../SystemLockedFieldConfig/SystemLockedFieldConfig';
import { CustomizedFieldConfig } from '../../CustomizedFieldConfig/CustomizedFieldConfig';
import { StatusFieldConfig } from '../../StatusFieldConfig/StatusFieldConfig';
import { LastUpdatedFieldConfig } from '../../LastUpdatedFieldConfig/LastUpdatedFieldConfig';
import { LastUpdatedByFieldConfig } from '../../LastUpdatedByFieldConfig/LastUpdatedByFieldConfig';
export const PicklistPageFieldConfigurations = (setPopoutPicklist) => {
    return [
        PicklistNameFieldConfig(setPopoutPicklist),
        IdFieldConfig(),
        TypeFieldConfig(),
        SystemLockedFieldConfig(),
        CustomizedFieldConfig(),
        StatusFieldConfig(),
        LastUpdatedFieldConfig(),
        LastUpdatedByFieldConfig(),
    ];
};
export default PicklistPageFieldConfigurations;
