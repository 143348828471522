import React, { useState } from 'react';
import './PicklistPage.css';
import { usePicklists } from '@samc/picklist-api';
import { PicklistType } from '@samc/picklist-api/lib/model/Picklist';
import { usePicklistDeleter } from '@samc/picklist-api/lib/usePicklistDeleter/usePicklistDeleter';
import { SplitButton, toastError, toastSuccess } from '@samc/react-ui-core';
import { useHeaderContext } from '@samc/react-ui-history';
import { ServerSidePicklistCrudEditorPanel } from '../../molecules/panels/ServerSidePicklistCrudEditorPanel/ServerSidePicklistCrudEditorPanel';
import PicklistGrid from '../../grid/grids/PicklistGrid/PicklistGrid';
const defaultPicklist = {
    id: '',
    description: '',
    name: '',
    isLocked: false,
    targetDomainIds: [],
    status: 'Active',
    isCustomized: true,
    isUserDefined: true,
    type: PicklistType.Custom,
};
export const PicklistPage = (props) => {
    const { editingPicklistId } = props;
    const pickListStatus = usePicklists();
    const { setValue: setHeaderValue } = useHeaderContext();
    const [deleter] = usePicklistDeleter();
    const handleDeletion = (id) => {
        deleter(id)
            .then(() => {
            toastSuccess('Deletion succeeded');
            pickListStatus.refresh();
        })
            .catch((e) => toastError(`Deletion failed: ${e}`));
    };
    const picklists = React.useMemo(() => {
        if (pickListStatus.isLoading || pickListStatus.isError)
            return [];
        return pickListStatus.data;
    }, [pickListStatus.isLoading, pickListStatus.isError, pickListStatus.data]);
    const [popoutPicklist, setPopoutPicklist] = useState();
    const handleCrudClose = React.useCallback(() => setPopoutPicklist(undefined), []);
    React.useEffect(() => {
        setPopoutPicklist(picklists === null || picklists === void 0 ? void 0 : picklists.find((p) => p.id === editingPicklistId));
    }, [editingPicklistId, picklists]);
    const onAdd = React.useCallback(() => {
        setPopoutPicklist(defaultPicklist);
    }, []);
    const onSelect = React.useCallback((picklist) => {
        if (!picklists || !picklist)
            return;
        const selected = picklists.find((x) => x.id === picklist.id);
        if (selected)
            setPopoutPicklist(selected);
    }, [picklists]);
    const splitButtonItems = React.useMemo(() => {
        return [
            {
                displayText: 'Add',
                onClick: onAdd,
            },
        ];
    }, [onAdd]);
    React.useEffect(() => {
        setHeaderValue('controlBarProps', (c) => (Object.assign(Object.assign({}, c), { additionalControls: React.createElement(SplitButton, { items: splitButtonItems, text: "Actions" }) })));
    }, [setHeaderValue, splitButtonItems]);
    React.useEffect(() => {
        setHeaderValue('tabs', [{ header: 'All Picklists', id: 'all-picklists' }]);
        setHeaderValue('selectedTabId', 'all-picklists');
    }, [setHeaderValue]);
    return (React.createElement("div", { className: "picklist-body" },
        React.createElement(PicklistGrid, { onEdit: onSelect, picklists: picklists, onDelete: handleDeletion }),
        popoutPicklist && (React.createElement(ServerSidePicklistCrudEditorPanel, { picklist: popoutPicklist, onClose: handleCrudClose, isOpen: true, onUpdate: () => pickListStatus.refresh() }))));
};
export default PicklistPage;
