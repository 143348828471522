const RootIdValueGetter = (params) => {
    const { data } = params;
    if (!data)
        return '';
    const { parentId, id, isSystemDefined } = data;
    if (!parentId && !id) {
        return '';
    }
    if (isSystemDefined || parentId) {
        return parentId !== null && parentId !== void 0 ? parentId : id;
    }
    return id;
};
export default RootIdValueGetter;
