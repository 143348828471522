var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { isNullOrUndefined, Toggle } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { getDisplayValue, insensitiveEqual } from '../Picklist/PicklistFunctions';
const PicklistToggleInner = (props) => {
    const { filteredItems, unrecognizedItems, valueProperty, displayProperty, disabled, className, hasError, isUpdated, selectedKey, suppressItemTooltips, onChange, enableKeyboardNavigation, isRequired, fillCellColor, filterObject, } = props;
    const [css] = useStyletron();
    const unrecognizedSelectionClass = css({
        color: '#FF0000 !important',
    });
    /**
     * Items for selected keys that do not correspond with items.
     */
    const unrecognizedToggleOptions = React.useMemo(() => {
        return unrecognizedItems.map((o) => ({
            displayText: String(o),
            id: o,
            className: unrecognizedSelectionClass,
            hoverText: suppressItemTooltips ? '' : 'Missing option',
            disabled: true,
        })); // has bad input keys
    }, [suppressItemTooltips, unrecognizedItems, unrecognizedSelectionClass]);
    const items = React.useMemo(() => unrecognizedToggleOptions.concat(filteredItems
        .filter((i) => !isNullOrUndefined(i[valueProperty]))
        .map((i) => {
        const displayText = String(getDisplayValue(i, displayProperty, 'id'));
        return {
            displayText,
            id: i[valueProperty],
            data: i,
            hoverText: suppressItemTooltips ? '' : undefined,
            disabled,
            displayExpression: i.displayExpression,
        };
    })), [disabled, displayProperty, filteredItems, suppressItemTooltips, unrecognizedToggleOptions, valueProperty]);
    return (React.createElement(Toggle, { className: className, hasError: hasError, isUpdated: isUpdated, options: items, comparator: (a, b) => (insensitiveEqual(a, b) ? 0 : 1), value: selectedKey, onSelect: (v) => onChange === null || onChange === void 0 ? void 0 : onChange(v.id), enableKeyboardNavigation: enableKeyboardNavigation, isRequired: isRequired, fillCellColor: fillCellColor, expressionData: filterObject }));
};
const _PicklistToggle = (props, ref) => {
    const { fetchItems, selectedKey, onChange, valueProperty, suppressItemTooltips, displayProperty, disabled, className, isUpdated, hasError, enableKeyboardNavigation, isRequired, fillCellColor, filterObject, } = props;
    const [toggleItems, setToggleItems] = React.useState([]);
    const missingSelection = React.useMemo(() => (selectedKey && toggleItems.every((i) => !insensitiveEqual(i.id, selectedKey)) ? [selectedKey] : []), [selectedKey, toggleItems]);
    const loadItems = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const output = [];
        let offset = 0;
        while (output.length < 10) {
            // eslint-disable-next-line no-await-in-loop
            const { items, totalCount } = yield fetchItems({
                limit: 20,
                offset,
            });
            output.push(...items.filter((i) => !i.hidden));
            offset += items.length;
            if (totalCount <= offset)
                break;
        }
        setToggleItems(output);
    }), [fetchItems]);
    React.useEffect(() => {
        loadItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useImperativeHandle(ref, () => ({
        getSelectedItems: () => {
            const item = toggleItems.find((i) => insensitiveEqual(i.id, selectedKey));
            if (!item)
                return [];
            return [item];
        },
        reload: () => loadItems(),
    }), [loadItems, selectedKey, toggleItems]);
    return (React.createElement(PicklistToggleInner, { enableKeyboardNavigation: enableKeyboardNavigation, filteredItems: toggleItems, unrecognizedItems: missingSelection, suppressItemTooltips: suppressItemTooltips, displayProperty: displayProperty, valueProperty: valueProperty, disabled: disabled, selectedKey: selectedKey, className: className, onChange: onChange, hasError: hasError, isUpdated: isUpdated, isRequired: isRequired, fillCellColor: fillCellColor, filterObject: filterObject }));
};
export const PicklistToggle = React.forwardRef(_PicklistToggle);
export default PicklistToggle;
