// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-af2e410bcb/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-root .picklist-cell-editor .searchable-dropdown, .ag-root .picklist-cell-editor .multicolumn-picklist {
    border: unset;
    height: 100%;
}

.ag-root .picklist-cell-editor {
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-picklist-core-virtual-8f0471469b/1/packages/core/lib/grid/editors/PicklistCellEditor/PicklistCellEditor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".ag-root .picklist-cell-editor .searchable-dropdown, .ag-root .picklist-cell-editor .multicolumn-picklist {\n    border: unset;\n    height: 100%;\n}\n\n.ag-root .picklist-cell-editor {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
