import moment from 'moment';
import { DateValueGetter } from '../../value-getters/DateValueGetter/DateValueGetter';
const comparator = (selectedDate, dateString) => {
    const targetDate = moment(dateString, 'DD-MMM-YYYY hh:mmA').startOf('day').toDate();
    return targetDate.valueOf() - selectedDate.valueOf();
};
export const LastUpdatedFieldConfig = () => {
    return {
        headerName: 'Last Updated',
        field: 'lastUpdated',
        valueGetter: DateValueGetter,
        width: 190,
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            comparator,
        },
        floatingFilter: true,
    };
};
export default LastUpdatedFieldConfig;
