var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { getIndexableDomainsQueryKey, useIndexableDomains } from '../useIndexableDomains/useIndexableDomains';
import { INDEXABLE_DOMAINS_KEY } from '../useDomainGetter/useDomainGetter';
export const DOMAINS_KEY = INDEXABLE_DOMAINS_KEY;
export const getDomainsQueryKey = () => getIndexableDomainsQueryKey();
export const useDomains = () => {
    const _a = useIndexableDomains(), { data } = _a, rest = __rest(_a, ["data"]);
    const flattened = React.useMemo(() => (data ? Object.values(data) : undefined), [data]);
    return Object.assign(Object.assign({}, rest), { data: flattened });
};
