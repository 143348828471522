import { useRequestBase, getQueryKey } from '@samc/react-ui-request';
import { useQueryClient } from 'react-query';
import { getDomains } from '../api/Requests';
import { ApiContext, useApiContext } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import { getDomainQueryKey, INDEXABLE_DOMAINS_KEY } from '../useDomainGetter/useDomainGetter';
/**
 * Gets the query key used to fetch all picklists
 */
export const getIndexableDomainsQueryKey = () => getQueryKey(INDEXABLE_DOMAINS_KEY, []);
/**
 * Retrieves a record of all picklists by id
 */
export const useIndexableDomains = () => {
    const api = useApiContext();
    const queryClient = useQueryClient();
    const query = () => getDomains(api.AggregatorApi, api.requestInit).then((flatResponse) => {
        return flatResponse.reduce((all, cur) => {
            // force the latest and greatest into the query context, will push to any useRequest hooks.
            queryClient.setQueryData(getDomainQueryKey(cur.id), cur);
            return Object.assign(Object.assign({}, all), { [cur.id]: cur });
        }, {});
    });
    return useRequestBase({
        key: INDEXABLE_DOMAINS_KEY,
        query,
        requestContext: ApiContext,
        options: {
            staleTime: CacheTime.domain,
        },
    });
};
export default useIndexableDomains;
