export const CustomLongNameFieldConfig = (isCustom) => {
    return {
        headerName: 'Custom Long Name',
        field: 'customLongName',
        type: 'string',
        editableRule: 'true',
        requiredFieldRule: isCustom ? 'false' : 'isnull([isSystemDefined]) or [isSystemDefined] != true',
        width: 200,
    };
};
export default CustomLongNameFieldConfig;
